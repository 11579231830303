<ess-dialog-title i18n>
    Share Poll
</ess-dialog-title>
<mat-dialog-content class="dialog-content">

    <share-poll [showHeadline]="true" [copyToClipboardOnInit]="true"></share-poll>

</mat-dialog-content>


<ess-dialog-actions i18n-confirmLabel confirmLabel="Got it" (confirmEvent)="dialogRef.close()" [showCancel]="false">
</ess-dialog-actions>