<div [ngClass]="themeType + '-theme'">
    <!--Menu Button with all possile actions - context sensitive on logged in an anonnym users-->
    <span *ngIf="appearance==='navBar'" class="menu-button">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
            <mat-icon>menu</mat-icon>
        </button>
    </span>
    <span *ngIf="appearance==='fullScreen'">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
            <mat-icon>menu</mat-icon>
        </button>
    </span>
    <mat-menu #menu="matMenu">

        <button mat-menu-item (click)="openCreatePollDialog()">
            <mat-icon>add_circle</mat-icon>
            <span i18n>Create</span>
        </button>
        <button *ngIf="authService.isLoggedIn" mat-menu-item routerLink="{{trans.getRoutePath('myPolls')}}">
            <mat-icon>view_list</mat-icon>
            <span i18n>My events</span>
        </button>
        <button *ngIf="authService.isLoggedIn && !authService.isAnonymous" mat-menu-item
            routerLink="{{trans.getRoutePath('userProfile')}}">
            <mat-icon>settings</mat-icon>
            <span i18n>My profile</span>
        </button>

        <button mat-menu-item routerLink="{{trans.getRoutePath('blog')}}">
            <mat-icon>description</mat-icon>
            <span i18n>Blog</span>
        </button>
        <button mat-menu-item routerLink="{{trans.getRoutePath('feedback')}}">
            <mat-icon>add_comment</mat-icon>
            <span i18n>Contact</span>
        </button>
        <button mat-menu-item routerLink="{{trans.getRoutePath('about')}}">
            <mat-icon>sports_kabaddi</mat-icon>
            <span i18n>About us</span>
        </button>
        <button mat-menu-item routerLink="{{trans.getRoutePath('chooseLanguage')}}">
            <mat-icon>language</mat-icon>
            <span i18n>Language</span>
        </button>

        <button *ngIf="authService.isLoggedIn  && authService.isAnonymous" mat-menu-item
            routerLink="{{trans.getRoutePath('upgradeAnonymous')}}">
            <mat-icon>key</mat-icon>
            <span> {{trans.label('actionLabels','personalAccess')}}</span>
        </button>
        <button *ngIf="authService.isLoggedIn  && authService.isAnonymous" mat-menu-item
            routerLink="{{trans.getRoutePath('upgradeAnonymous')}}">
            <mat-icon>person_add</mat-icon>
            <span i18n>Sign up</span>
        </button>

        <button *ngIf="!authService.isLoggedIn" mat-menu-item routerLink="{{trans.getRoutePath('userLogin')}}">
            <mat-icon>login</mat-icon>
            <span i18n>Login</span>
        </button>
        <button *ngIf="!authService.isLoggedIn" mat-menu-item routerLink="/user/signup">
            <mat-icon>person_add</mat-icon>
            <span i18n>Sign up</span>
        </button>
        <button *ngIf="authService.isLoggedIn" mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span i18n>Log out</span>
        </button>
    </mat-menu>
</div>