import { Injectable } from '@angular/core';
import { LOCALE_ID, Inject } from '@angular/core';
import dicEn from '../../assets/locale/en/general.en.json';
import dicDe from '../../assets/locale/de/general.de.json';
import dicFr from '../../assets/locale/fr/general.fr.json';
import dicEs from '../../assets/locale/es/general.es.json';
import dicPt from '../../assets/locale/pt/general.pt.json';
import dicIt from '../../assets/locale/it/general.it.json';


import { NGXLogger as LoggerService } from "ngx-logger";
import { Router } from '@angular/router';

const languageCodes = ["en", "de", "it", "fr", "es", "pt"]

const dictionaries = [
    { langCode: "en", dictionary: dicEn },
    { langCode: "de", dictionary: dicDe },
    { langCode: "it", dictionary: dicIt },
    { langCode: "fr", dictionary: dicFr },
    { langCode: "es", dictionary: dicEs },
    { langCode: "pt", dictionary: dicPt },
]
@Injectable({
    providedIn: 'root'
})
export class AlternateHrefService {
    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private logger: LoggerService,
        private router: Router,

    ) {
    }

    public getAlternateHrefLangs(pathId: string): { langCode: string, url: string }[] {
        let returnArray: { langCode: string, url: string }[] = []
        // translated route
        if (this.isTranslatedRoute(pathId)) {
            this.logger.log('isTranslatedRoute', pathId)
            dictionaries.forEach(dictionary => {
                if (dictionary.dictionary.seo.routes[pathId]) {
                    returnArray.push({ langCode: dictionary.langCode, url: dictionary.dictionary.seo.routes[pathId] })
                }
            })
            //blog
        } else if (this.isBlogRoute(pathId)) {
            this.logger.log('isBlogRoute', pathId)
            // will be handled in the blog module
            //            returnArray = this.blogService.getAlternateHrefLangs();
            // all others
        } else {
            const url = this.router.url.replace("/", "")
            this.logger.log('else', pathId, url)
            languageCodes.forEach(languageCode => {
                returnArray.push({ langCode: languageCode, url: url });
            })
        }
        return returnArray
        // else
    }

    private isTranslatedRoute(pathId: string): boolean {
        return dicEn.seo.routes[pathId] ? true : false;
    }

    private isBlogRoute(pathId: string): boolean {
        return pathId === "blog-post" ? true : false;
    }



}
