import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxEssentiaModule } from 'ngx-essentia';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../shared/services/responsive.service';
import { TranslationService } from '../../../shared/services/translation.service';
import { UIMesageService } from '../../../shared/services/ui-message.service';
import { CurrentPollService } from '../../core/current-poll.service';
import { HeaderService } from '../poll-header/header.service';
import { NavigationService } from '../../core/navigation.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ProviderSignInComponent } from '../../../user/shared/provider-sign-in/provider-sign-in.component';
import { EmailSignupLoginComponent } from '../../../user/shared/email-signup-login/email-signup-login.component';
import { MatCardModule } from '@angular/material/card';
import { GoogleAnalyticsService } from '../../../shared/services/google-analytics.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'get-poll-access',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        ClipboardModule,
        MatFormFieldModule,
        MatInputModule,
        MatCardModule,
        NgxEssentiaModule,
        ProviderSignInComponent,
        EmailSignupLoginComponent
    ],
    templateUrl: './get-poll-access.component.html',
    styleUrls: ['./get-poll-access.component.scss'],
})
export class GetPollAccessComponent implements OnInit {
    public pollUrlForField: string;
    @Input() dialogRef: MatDialogRef<any>;

    constructor(
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        public headerService: HeaderService,
        private navigationService: NavigationService,
        public currentPollService: CurrentPollService,
        public trans: TranslationService,
        private clipboard: Clipboard,
        private googleAnayltics: GoogleAnalyticsService,



    ) { }

    ngOnInit(): void {
        this.logger.log('ngOnInit: ');
        this.pollUrlForField = this.pollUrl;
        this.googleAnayltics.emitSimpleEvent('permanent_access_link')
        this.copyToClipboard();
    }


    get pollUrl(): string {
        let returnValue: string;
        if (this.currentPollService.isInitialized) {
            if (this.currentPollService.isOwner) {
                returnValue = this.navigationService.getUrlAssignAccessPollResponses()
            } else {
                returnValue = this.navigationService.getUrlAssignAccessVote()
            }
        } else {
            returnValue = this.navigationService.getUrlAssignAccessMyPolls()

        }
        return returnValue
    }

    public handleClickCopyToClipboard() {
        this.copyToClipboard();
        this.dialogRef?.close()

    }

    public copyToClipboard() {
        this.logger.log('sendUiMessage');
        this.clipboard.copy(this.pollUrl);
        this.uiMessage.success(this.trans.dic.poll.urlCopied, null, 'bottom');

    }

    public handleClickSignUp() {
        this.navigationService.navigate(this.trans.getRoutePath('upgradeAnonymous'))
        this.dialogRef?.close()

    }

}
