
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger as LoggerService } from "ngx-logger";
import { VotesService } from '../features/vote/votes.service';
import { OptionsService } from '../shared/options/options.service';
import { PollResultsService } from './poll-results.service';
import { HeaderService } from '../shared/poll-header/header.service';
import { CurrentPollService } from './current-poll.service';
import { OptionList, PollKeysClass } from 'whocan-lib';
import { AuthService } from '../../user/auth.service';
import { TranslationService } from '../../shared/services/translation.service';
import { TemplateService } from './poll-templates/template.service';
import { GoogleAnalyticsService } from '../../shared/services/google-analytics.service';
import { VoteData } from 'whocan-lib';
import { EssErrorService } from 'ngx-essentia';
import { UserVoteService } from '../features/vote/user-vote.service';
import { PollParticipantsService } from '../shared/poll-header/poll-participants.service';

@Injectable({
    providedIn: 'root'
})
/**
 * Service to create, inititiallize, copy etc  polls.
 * By initiializing the poll we load the data
 * The data is not hold directly here but in the unlerlying OptionsService, VotesService etc
 *
 */
export class PollService {
    private _isInitializing: boolean = false
    public pollExist = false;
    constructor(
        public authService: AuthService,
        private logger: LoggerService,
        private currentPollService: CurrentPollService,
        public votesService: VotesService,
        private optionsService: OptionsService,
        public pollResultsService: PollResultsService,
        private voteService: VotesService,
        private userVoteService: UserVoteService,
        public headerService: HeaderService,
        private trans: TranslationService,
        private pollParticipantsService: PollParticipantsService,
        public templateService: TemplateService,
        private googleAnayltics: GoogleAnalyticsService,
        private errorService: EssErrorService,


    ) {
    }

    public initNewPoll(pollTemplateKey: string, pollTitle: string, themeKey?: string) {
        this.logger.log('initNewPoll', pollTitle);
        // this is important because we try to delte the current poll when canceling the create poll wizard
        this.unInitializePoll();
        this.templateService.initTemplate(pollTemplateKey);
        this.headerService.initNewHeader(pollTemplateKey, pollTitle, themeKey)

    }


    public async persistNewPoll(): Promise<void> {
        this.logger.log('persistNewPoll');
        if (!this.authService.isLoggedIn) {
            await this.authService.signUpAnonymous('');
        }
        const ownerId = await this.authService.getUid();
        const pollId = await this.headerService.getNewHeaderKey(ownerId);
        this.currentPollService.setCurrentPoll(ownerId, pollId);
        this.headerService.headerClass.ownerId = ownerId
        this.headerService.headerClass.pollId = pollId
        await this.headerService.persist();
        this.optionsService.optionList = this.templateService.getDefaultOptionList()
        await this.optionsService.persist(true);
        this.googleAnayltics.emitPollCreated(this.headerService.headerClass.pollTemplate, this.headerService.headerClass.theme)
    }

    /**
     * Makes sure the user is loigged in , checks if the poll exits and inits the PathService
     * Throws anexception if the poll does not exist
     * @param routeParam
     */
    public async enterPollByRoute(activatedRoute: ActivatedRoute) {
        const ownerId = activatedRoute.snapshot.params['ownerId'];
        const pollId = activatedRoute.snapshot.params['pollId'];
        let voteUserId = activatedRoute.snapshot.params['voteUserId'];
        if (!voteUserId) { voteUserId = this.authService.uid }
        this.logger.log('enterPollByRoute onwerId:', ownerId, 'pollId: ', pollId, 'voteUserId', voteUserId);
        await this.initPollAndSubscribeData(ownerId, pollId, voteUserId);
    }

    public pollIsLoaded(): boolean {
        //        return (!this._isInitializing) && this.pollExist;
        return (!this._isInitializing) && this.userVoteService.userVote !== null && this.pollExist
    }

    /**
     * Checks if the poll exits,  inits the currentPollService and subscribe to Poll Data
     * @param ownerId
     * @param pollId
     */
    public async initPollAndSubscribeData(ownerId: string, pollId: string, voteUserId?: string) {
        this.initPoll(ownerId, pollId, voteUserId);
        this.logger.log('initPollAndSubscribeData', ownerId, pollId, voteUserId);
        this._isInitializing = true
        if (!(await this.authService.getIsLoggedIn())) {// create an anonymous user for now
            await this.authService.signUpAnonymous('');
        }
        this.pollExist = await this.pollExists(ownerId, pollId);
        if (this.pollExist) {
            this.logger.log('enterPoll : initService voteUserId', voteUserId);
            await this.loadPollData(voteUserId);
            this._isInitializing = false
        } else {
            this._isInitializing = false
            throw this.errorService.newError(this.trans.dic.poll.notExist + ": " + ownerId + "/" + pollId, false)
        }
    }

    public unInitializePoll() {
        this.headerService.headerClass?.uninitialize()
        this.optionsService.uninitialize();
        this.voteService.uninitialize();
        this.currentPollService.unInitializeCurrentPoll();
    }

    public async initPoll(ownerId: string, pollId: string, voteUserId?: string) {
        this.logger.log('initPoll', ownerId, pollId, voteUserId);
        this.unInitializePoll();
        this.currentPollService.setCurrentPoll(ownerId, pollId, voteUserId);
    }

    public async loadPollData(voterUid?: string) {
        await this.headerService.load();
        await this.optionsService.load();
        await this.votesService.load();
        await this.userVoteService.initUserVote(voterUid)
    }

    public async pollExists(ownerId: string, pollId: string): Promise<boolean> {
        this.logger.log('surveyExists? start; ownerId: ' + ownerId + ' surveId: ' + pollId);
        return this.headerService.headerExist(ownerId, pollId);
    }

    public generatePollId(ownerId?: string): string {
        this.logger.log('generatePollId');
        return this.headerService.getNewHeaderKey(ownerId);
    }

    public async deletePoll(ownerId: string, pollId: string) {
        this.headerService.deleteHeader(ownerId, pollId);
        this.optionsService.deleteOptions(ownerId, pollId);
        // loop all the votes to delete all the participations
        const votes = await this.voteService.getVotes(ownerId, pollId);
        votes.forEach(async vote => {
            const userId = vote.userId;
            await this.pollParticipantsService.deleteParticipation(ownerId, pollId, userId);

        });
        this.votesService.deleteVotes(ownerId, pollId);
    }

    async copyPoll(ownerId: string, pollId: string): Promise<PollKeysClass> {
        this.logger.log('copyPoll: ', pollId);
        const newOwnerId = this.authService.uid;
        const newPollId = await this.headerService.copyHeader(ownerId, pollId, this.authService.uid, this.authService.displayName);
        if (newPollId) {
            const resultOptions = await this.optionsService.copyAndPersistAllOptions(ownerId, pollId, this.authService.uid, newPollId);
            if (resultOptions) {
                this.logger.log('SurveyOptions Copied');
                return new PollKeysClass({ ownerId: newOwnerId, pollId: newPollId });
            }
        } else {
            throw (new Error(this.trans.dic.poll.notExist));
        }
    }

}
