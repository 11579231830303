import { Inject, Injectable, LOCALE_ID, Optional } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { browserPopupRedirectResolver, signInWithPopup } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { NGXLogger as LoggerService } from "ngx-logger";
import { TranslationService } from '../shared/services/translation.service';
import { UserService } from './user.service';
import { Delegation as DelegationRecord } from 'functions-lib';
import UserCredential = firebase.auth.UserCredential;
import { EssErrorService } from 'ngx-essentia';

export enum AuthProvider {
    ALL = 'all',
    EmailAndPassword = 'firebase',
    Google = 'google',
    Apple = 'apple',
    Facebook = 'facebook',
    Twitter = 'twitter',
    Github = 'github',
    Microsoft = 'microsoft',
    Yahoo = 'yahoo',
    PhoneNumber = 'phoneNumber',
}

/**
 * Provides all services related to authentication with Firebase
 * Makes also sure that we subscribe to additional user information from the service DatabaseUserService
 * subscribeAuthAndDbUser()  should be called once when starting the app, We do that via the constructor
 * (there are other ways to do so but this is easy and seems to work)
 * subscribeAuthAndDbUser makes sure we get the latest data in afAuthUser (=== this.afAuth.auth.currentUser)
 * afAuthUser$  can be used to subscribe changes on afAuthUser for example to updates reactive form fields
 * inspirey by NgxAuthFirebaseUI
 *
 * Claims are set via backend : setDelegationCustomClaim
 */

@Injectable({
    providedIn: 'root', // specifies that Angular should provide the service in the root injector - ?? I gues we need that to init the the service in app.module
})
export class AuthService {
    public isLoading = false;
    private authUserSubscription: Subscription;
    private afAuthUser: firebase.User = null;
    private token: firebase.auth.IdTokenResult = null;

    constructor(
        // TODO: Replace with tree shakable @angular/fire/auth
        private afAuth: AngularFireAuth,
        @Optional() private auth: Auth,
        private errorService: EssErrorService,
        private logger: LoggerService,
        private databaseUserService: UserService,
        private router: Router,
        private trans: TranslationService,
        @Inject(LOCALE_ID) public locale: string,

    ) {
        this.initService()
    }


    private async initService() {
        firebase.auth().languageCode = this.locale;
        this.processRedirectResult(); // have to wait for the redirect result ohterwise it might come to conflicts
        this.subscribeAuthAndDbUser();
    }
    /**
     * Subscribe to changes on afAuthUser and with every change we subscribe to databaseUserService.getDbUserObservable
     * so that we get the latest date in this.databaseUserService.currentDbUser
     */
    private subscribeAuthAndDbUser() {
        this.logger.log('subscribeAuthAndDbUser');
        //  this.afAuth.user.subscrib is called before this.afAuth.authState
        this.authUserSubscription = this.afAuth.user.subscribe(async (afAuthUser) => {
            this.logger.log('subscribeAuthAndDbUser ', afAuthUser);
            this.afAuthUser = afAuthUser;
            this.setDelegationFromSessionStorage()// call here again in case the processRedirectResult failed
            this.loadIdTokenResult();
            this.databaseUserService.subscribeDbUser(afAuthUser?.uid);
        });
    }

    private async loadUser(uid: string) {
        this.isLoading = true;
        try {
            if (!this.authUserSubscription) {
                this.subscribeAuthAndDbUser();
            }
            this.afAuthUser = await firstValueFrom(this.afAuthUser$);
            this.databaseUserService.createAndLoadDbUser(uid);
        } catch (error) {
            throw (this.errorService.newError('Error loading user' + error))
        } finally {
            this.isLoading = false;
        }
    }

    /**
     *
     * SignIn &  Login
     *
     */

    private async handleAfterSignIn(user: firebase.User) {
        this.logger.log('handleAfterSignIn');
        this.afAuthUser = user;
        await this.loadUser(this.afAuthUser.uid);
        this.handleNavigation();
    }

    public async signUpAnonymous(displayName: string) {
        this.logger.log('signUpAnonymous, displaynname: ' + displayName);
        const signInResult = await this.afAuth.signInAnonymously();
        if (!signInResult || !signInResult.user) {
            throw this.errorService.newError(this.trans.dic.internalError);
        }
        this.afAuthUser = signInResult.user;
        this.logger.log('signUpAnonymous: signInResult: ', signInResult, this.afAuthUser.uid);
        await this.loadUser(this.afAuthUser.uid);
        this.logger.log('signUpAnonymous: updateUserDoc done ');
    }

    public async signUpEmail(displayName: string, email: string, password: string) {
        this.isLoading = true
        try {
            const userCredential: UserCredential =
                await this.afAuth.createUserWithEmailAndPassword(
                    email,
                    password
                );
            const afUser = userCredential.user;
            // make sure the new user is loaded before coming back, otherwise setting the delegatedFrom might go wrong
            await this.loadUser(afUser.uid);
            await this.setDisplayName(displayName);
            if (!afUser) this.logger.error('signUpEmail !afUser)')
            await afUser.sendEmailVerification();
            this.handleAfterSignIn(userCredential.user)

        } catch (error) {
            throw (error)
        } finally {
            this.isLoading = false;
        }
    }


    public async signInWithEmail(email: string, password: string) {
        this.logger.log('signInWithEmail')
        let signInResult = await this.afAuth.signInWithEmailAndPassword(email, password);
        await this.handleAfterSignIn(signInResult.user)
    }

    public async mergeWithPasswordAccount(email: string, password: string) {
        this.logger.log('mergeWithPasswordAccount', email);
        // set the delegatedTo on the anonymous user (this.fbUser)
        const delegationRecord =
            await this.databaseUserService.getDelegationRecord(this.uid);
        // sign in with email credentials
        await this.signInWithEmail(email, password,);
        this.databaseUserService.dbUserSubscription.unsubscribe()
        // set the delegatedFrom on  user
        await this.databaseUserService.setNewDelegationRecord(
            delegationRecord,
            this.uid
        );
        this.refreshIdTokenResult();
    }

    public async upgradeAnonymousWithEmail(emailAddress: string, password: string
    ) {
        this.logger.log(
            'upgradeAnonymousWithEmail, ',
            emailAddress,
            password
        );
        const credential = firebase.auth.EmailAuthProvider.credential(
            emailAddress,
            password
        );
        const signInResult = await this.afAuthUser.linkWithCredential(credential);
        if (!this.afAuthUser) this.logger.error('upgradeAnonymousWithEmail !afUser)')
        this.afAuthUser.sendEmailVerification();
        //    await this.afAuth.auth.currentUser.updateEmail(emailAddress);
        await this.databaseUserService.createAndLoadDbUser(
            this.afAuthUser.uid
        );
        await this.handleAfterSignIn(signInResult.user)
    }

    public async signInWithProvider(provider: AuthProvider) {
        const firebaseProvider = this.convertProvider(provider);
        const emulateNoPopUp = false;  // for testing purpose only
        try { // try always first popup
            if (emulateNoPopUp) {
                const error = { message: 'emulateNoPopUp', code: "auth/popup-blocked" }
                throw (error)
            }
            let result = await signInWithPopup(this.auth, firebaseProvider, browserPopupRedirectResolver);
            this.handleAfterSignIn(<firebase.User>result.user)
        } catch (popUpError) {
            if (popUpError.code === 'auth/popup-blocked') {
                this.logger.log('signInWithProvider: Popup Blocked');
                try {
                    this.afAuth.signInWithRedirect(firebaseProvider);
                    // redirect triggers a reload so the program flow stops here
                    //  so we cant call handleAfterSignIn , instead this is called via processRedirectResult and getRedirectResult
                } catch (redirectError) {
                    let message = 'Pop-up window has been blogged. SignInWithRedirect failed. Please try again or enable popup windows in your browser. (Error-code:' + JSON.stringify(redirectError) + ')';
                    throw this.errorService.newError(message)
                }
            }
            else {// popup failed but not because of popup-blocked
                throw popUpError
            }
        }
    }

    public async mergeWithProviderAccount(provider: AuthProvider) {
        this.logger.log('mergeWithProviderAccount', provider);
        // set the delegatedTo on the anonymous user (this.fbUser)
        const delegation = await this.databaseUserService.getDelegationRecord(
            this.uid
        );
        window.sessionStorage.setItem('delegationFromUid', delegation.delegatedFromUid)// needs to persist for the loginWithRedirect
        window.sessionStorage.setItem('delegationSecretKey', delegation.secretKey)
        this.logger.log('mergeWithProviderAccount seession storage', delegation)
        await this.signInWithProvider(provider);
        this.databaseUserService.dbUserSubscription.unsubscribe()
        // set the delegatedFrom on  user
        await this.databaseUserService.setNewDelegationRecord(delegation, this.uid);
        this.refreshIdTokenResult();
    }

    /**
     * Because redirect triggers a reload so the program flow stops
     * is called in the constructor to process the redirect result
     */
    private async processRedirectResult(): Promise<void> {
        this.logger.log('processRedirectResult')
        this.isLoading = true;
        try {
            if (this.isLoggedIn) {
                await this.setDelegationFromSessionStorage()
            }
            await this.checkRedirectResultForErrors()
            this.handleNavigation();
        } catch (error) {
            this.logger.error(error)
        } finally {
            this.isLoading = false;
        }
    }

    private async checkRedirectResultForErrors() {
        let userCredential
        try {
            userCredential = await this.afAuth.getRedirectResult()
            this.logger.log('checkRedirectResultForErrors', userCredential)
        } catch (error) {
            let errorMessage = ""
            if (error.code === 'auth/account-exists-with-different-credential') {
                throw new Error('You have already signed up with a different auth provider for that email.')
            } else if (error.code === "auth/missing-or-invalid-nonce") { // this is a conflict with loading the user  - it soes not  cause an issue so we ignore
                errorMessage = "Error can be ignored"
                this.logger.warn(errorMessage, error, userCredential);
            }
            else {
                throw new Error('There was a problem with the sign in. Error code:  ' + error.code)
            }
        }
    }

    private async setDelegationFromSessionStorage() { // required to deal with signinWithRedirect
        const delegationFromUid = window.sessionStorage.getItem('delegationFromUid',)
        this.logger.log('processRedirectResult delegationFromUid', delegationFromUid)
        if (delegationFromUid) {
            this.logger.log('processRedirectResult')
            window.sessionStorage.removeItem('delegationFromUid')
            const delegationRecord: DelegationRecord = { secretKey: "", delegatedFromUid: delegationFromUid }
            const delegationSecretKey = window.sessionStorage.getItem('delegationSecretKey')
            if (delegationSecretKey) {
                delegationRecord.secretKey = delegationSecretKey
                window.sessionStorage.removeItem('delegationSecretKey')
            }
            this.logger.log('processRedirectResult set delegation', delegationRecord)
            await this.databaseUserService.setNewDelegationRecord(delegationRecord, this.uid);
        }
    }

    /**
     * Helper functions
     */

    public async loadIdTokenResult() {
        this.token = await this.afAuthUser?.getIdTokenResult(true);
        this.logger.log('loadIdTokenResult', this.token?.claims);
    }


    /**
     * WE don't know how long the  firebase function to update the custom claims takes
     * So we update the token in several intervalls
     * Better solution might be using an observable as described here:
     * https://firebase.google.com/docs/auth/admin/custom-claims?hl=en#defining_roles_via_firebase_functions_on_user_creation
     */
    public refreshIdTokenResult() {
        setTimeout(() => {
            this.logger.log('getIdTokenResult 1000');
            this.loadIdTokenResult();
        }, 1000);
        setTimeout(() => {
            this.logger.log('getIdTokenResult 3000');
            this.loadIdTokenResult();
        }, 3000);
        setTimeout(() => {
            this.logger.log('getIdTokenResult 5000');
            this.loadIdTokenResult();
        }, 5000);;
        setTimeout(() => {
            this.logger.log('getIdTokenResult 7000');
            this.loadIdTokenResult();
        }, 7000);;
        setTimeout(() => {
            this.logger.warn('getIdTokenResult 10000');
            this.loadIdTokenResult();
        }, 10000);
        setTimeout(() => {
            this.logger.warn('getIdTokenResult 30000');
            this.loadIdTokenResult();
        }, 300000);
    }

    private handleNavigation() {
        this.logger.log('handleNavigation: ', this.navigateToUrl);
        if (this.navigateToUrl) {
            this.router.navigate([this.navigateToUrl]);
        }
        this.navigateToUrl = null;
        this.logger.log('redirectUrl reset');
    }


    private convertProvider(
        provider: AuthProvider
    ): firebase.auth.AuthProvider {
        switch (provider) {
            case AuthProvider.Google:
                return new firebase.auth.GoogleAuthProvider();
            case AuthProvider.Facebook:
                return new firebase.auth.FacebookAuthProvider();
            case AuthProvider.Twitter:
                return new firebase.auth.TwitterAuthProvider();
            case AuthProvider.Github:
                return new firebase.auth.GithubAuthProvider();
            default:
                throw this.errorService.newError(provider + ' is not avaible as authentication provider')
        }
    }


    /**
     * General functions
     */

    async sendNewVerificationEmail(): Promise<void | never> {
        if (!this.afAuthUser) this.logger.error('sendNewVerificationEmail !afUser)')
        return await this.afAuthUser.sendEmailVerification();
    }

    public async requestPasswordResetEmail(emailParam?: string) {
        let email: string;
        if (emailParam) {
            email = emailParam;
        } else {
            email = this.afAuthUser?.email;
        }
        await this.afAuth.sendPasswordResetEmail(email);
    }


    public async getEmailFromActionCode(code: string): Promise<string> {
        return (await this.afAuth.checkActionCode(code)).data.email

    }

    public async verifyEmail(code: string) {
        await this.afAuth.applyActionCode(code)
    }

    public async submitNewPassword(code: string, newPassword: string) {
        await this.afAuth.confirmPasswordReset(code, newPassword)
    }

    async logOut(): Promise<void> {
        try {
            this.isLoading = true
            this.afAuthUser = null;
            this.token = null;
            await this.databaseUserService.logout();
            await this.authUserSubscription.unsubscribe();
            this.authUserSubscription = null;
            await this.afAuth.signOut();
        } catch (error) {
            throw this.errorService.newError('Logout failed. ' + error)
        } finally {
            this.isLoading = false;
        }
    }

    /**
     *
     * getter and setters
     *
     */

    get navigateToUrl(): string {
        const returnValue = window.sessionStorage.getItem('redirectUrl')
        this.logger.log('get redirectUrl', returnValue)
        return window.sessionStorage.getItem('redirectUrl') // needs to perist for the loginWithRedirect
    }

    set navigateToUrl(url: string) {
        this.logger.log('set redirectUrl', url)
        if (url) {
            window.sessionStorage.setItem('redirectUrl', url) // needs to perist for the loginWithRedirect
        } else {
            sessionStorage.removeItem("redirectUrl");
        }
    }

    public setNavigateToUrlIfNotSet(url: string) { // we don't want to overwright what was set in authguard when coming from an external link
        if (!this.navigateToUrl) {
            this.navigateToUrl = url;
        }
    }

    /**
     * get driecty the observable to the auth user
     * this is need for reactive forms to updated values after ngInit
     */
    public get afAuthUser$(): Observable<firebase.User | null> {
        // return this.afAuth.user;
        return this.afAuth.authState;
    }

    /**
     * Can be userd in template *ngIf ; the result we change after the user is loaded
     */
    public get isLoggedIn(): boolean {
        if (this.afAuthUser) {
            return true;
        }
        return false;
    }

    /**
     * before creating an anonymous user when we enter a page we want to wait for the promise to make sure if the user is logged in
     * @returns
     */
    public async getIsLoggedIn(): Promise<boolean> {
        const afAuthUser = await firstValueFrom(this.afAuthUser$);
        if (afAuthUser) {
            return true;
        }
        return false;
    }

    public get isAnonymous(): boolean {
        if (this.afAuthUser?.isAnonymous) {
            // if an email upgrades the anonymous account but did no set the password the currentUser.isAnonymous
            // remains true but we want to treat him already like a proper user
            if (this.email == null || this.email === '') {
                return true;
            }
        }
        return false;
    }

    public get email(): string {
        return this.afAuthUser?.email;
    }

    public get providerIds(): string[] {
        if (this.afAuthUser?.providerData) {
            return this.afAuthUser?.providerData.map(
                (element) => element.providerId
            );
        }
    }

    public get uid(): string {
        return this.afAuthUser?.uid;
    }

    public async getUid(): Promise<string> {
        const afAuthUser = await firstValueFrom(this.afAuthUser$);
        if (afAuthUser) {
            return afAuthUser.uid;
        }
        return null;
    }

    public get displayName(): string {
        return this.afAuthUser?.displayName;
    }

    public getDelegatedUserIds(): string[] {
        const delegationsString = this.token?.claims?.delegations;
        if (delegationsString === null || delegationsString === undefined) {
            return [];
        }
        const delegations = delegationsString.split(',');
        return delegations;
    }

    public async getAllUserIds(): Promise<string[]> {
        await this.loadIdTokenResult();
        let pollOwnerIds: string[] = [];
        const delegatedUserIds = this.getDelegatedUserIds();
        pollOwnerIds = [...delegatedUserIds];
        pollOwnerIds.push(this.uid);
        return pollOwnerIds

    }

    public getProducts(): string[] | null {
        return this.token?.claims?.products;
    }

    public hasAProduct(): boolean {
        if (
            !this.token?.claims?.products ||
            this.token?.claims?.products.length === 0
        ) {
            return false;
        }
        return true;
    }

    public hasEditRights(uid: string, ownerId: string): boolean {
        const delegatedUids = this.getDelegatedUserIds();
        if (uid === ownerId || delegatedUids.includes(ownerId)) {
            this.logger.log('hasEditRights true')
            return true;
        }
        this.logger.log('hasEditRights false')
        return false;
    }

    public async setDisplayName(name: string): Promise<void> {
        return this.afAuthUser?.updateProfile({ displayName: name });
    }

    /**
    * @deprecated checkout :   https://cloud.google.com/identity-platform/docs/admin/email-enumeration-protection
    */
    public async accountExist(email: string): Promise<boolean> {
        this.logger.log('accountExist', email);
        const signInMethods = await this.afAuth.fetchSignInMethodsForEmail(
            email
        ); // somehow I don get the right value here
        this.logger.log('accountExist signInMethods', signInMethods);
        if (signInMethods.length > 0) {
            this.logger.log('accountExist true');
            return true;
        }
        this.logger.log('accountExist false');
        return false;
    }

    /**
    * @deprecated checkout :   https://cloud.google.com/identity-platform/docs/admin/email-enumeration-protection
    */
    public async hasProviderAccount(email: string): Promise<boolean> {
        const signInMethods = await this.afAuth.fetchSignInMethodsForEmail(
            email
        );
        this.logger.log('hasProviderAccount', signInMethods);
        const signInMethodsWoPassword = signInMethods.map((value) => {
            if (value !== 'password') {
                return value;
            }
        });
        if (signInMethodsWoPassword.length > 0) {
            this.logger.log('hasProviderAccount', true);
            return true;
        }
        this.logger.log('hasProviderAccount', false);
        return false;
    }

    public get hasPasswordAccount(): boolean {
        if (this.providerIds?.find((providerId) => providerId === 'password')) {
            return true;
        }
        return false;
    }

    /**
    * @deprecated checkout :   https://cloud.google.com/identity-platform/docs/admin/email-enumeration-protection
    */
    public async hasPasswordAccountByEmail(email: string): Promise<boolean> {
        const signInMethods = await this.afAuth.fetchSignInMethodsForEmail(
            email
        );
        this.logger.log('hasPasswordAccount', signInMethods);
        if (signInMethods.includes('password')) {
            this.logger.log('signInMethods.includes(password)');
            return true;
        }
        return false;
    }

    public passwordHasBeenVerified(): boolean {
        if (!this.hasPasswordAccount) {
            return true;
        }
        return this.afAuthUser?.emailVerified;
    }

    /**
     * @deprecated checkout :   https://cloud.google.com/identity-platform/docs/admin/email-enumeration-protection
    */
    public async getLinkedProviders(email: string): Promise<string[]> {
        this.logger.log('getLinkedProviders ', email);
        // this will in future not work anymore see:
        //https://cloud.google.com/identity-platform/docs/admin/email-enumeration-protection
        return await this.afAuth.fetchSignInMethodsForEmail(email);
    }
}
