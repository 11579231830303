<div [ngClass]="responsiveService.getBigSmall()">
    <div class="image-card-container"
        [style.backgroundImage]="'url(assets/images/poll-templates/' + templateService.getConfigAttribute('general','previewImage',templateKey) + ')'">
        <div class="image-card-content-container">
            <div class="tooltip-container">
                <div class="tooltip-title">
                    {{templateKey+'.home.title' | templateLabel}}
                </div>
                <div class="tooltip-content">
                    <ul>

                        <li *ngIf="templateService.getLabel( 'home','usp1', templateKey) !== ''">
                            {{templateService.getLabel( 'home','usp1', templateKey)}}
                        </li>
                        <li *ngIf="templateService.getLabel( 'home','usp2', templateKey) !== ''">
                            {{templateService.getLabel( 'home','usp2', templateKey)}}
                        </li>
                        <li *ngIf="templateService.getLabel( 'home','usp3', templateKey) !== ''">
                            {{templateService.getLabel( 'home','usp3', templateKey)}}
                        </li>
                        <li *ngIf="templateService.getLabel( 'home','usp4', templateKey) !== ''">
                            {{templateService.getLabel( 'home','usp4', templateKey)}}
                        </li>

                    </ul>
                </div>
            </div>
        </div>
        <div class="image-card-titel-container">
            <div class="image-card-titel">
                {{templateKey+'.home.title' | templateLabel}}
            </div>
        </div>

        <div class="image-card-subtitel-container">
            <div class="image-card-subtitel">
                {{templateKey+'.home.subTitle' | templateLabel}}
            </div>
        </div>
    </div>
