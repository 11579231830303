import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../shared/services/responsive.service';
import { TranslationService } from '../../../shared/services/translation.service';
import { NavigationService } from '../../core/navigation.service';
import { TemplateService } from '../../core/poll-templates/template.service';
import { TemplateKey } from '../../core/poll-templates/template.configuration';
import { TemplateLabelPipe } from '../../core/poll-templates/template-label/template-label.pipe';
import { TemplateIconComponent } from './template-icon.component';

@Component({
    selector: 'poll-templates',
    templateUrl: './poll-templates.component.html',
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatDialogModule,
        MatTooltipModule,
        RouterModule,

        TemplateLabelPipe,
        TemplateIconComponent
    ],
    styleUrls: ['./poll-templates.component.scss'],
})
export class PollTemplatesComponent {
    @Input() hideTemplates: string[];
    @Input() pollTitle: string;
    @Input() singleColor = false;
    @Input() showAdvancedLink = false;
    @Input() openDialog = true;
    @Output() clickTemplateEvent = new EventEmitter<string>();

    constructor(
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        public dialog: MatDialog,
        public trans: TranslationService,
        public navigationService: NavigationService,
        public templateService: TemplateService
    ) { }



    public showTemplate(templateKey: string): boolean {
        if (this.hideTemplates && this.hideTemplates.includes(templateKey)) {
            return false;
        }
        return true;
    }

    public handleClickTemplate(templateKey?: string) {
        this.logger.log('handleClickTemplate', templateKey, this.openDialog);
        if (!templateKey) {
            templateKey = TemplateKey.advancedNew;
        }
        if (this.openDialog) {
            this.navigationService.navigateCreatePoll(
                this.pollTitle,
                templateKey
            );
            this.templateService.initTemplate(templateKey);
        } else {
            this.navigationService.navigate(
                this.trans.getRoutePath(templateKey)
            );
        }
        this.clickTemplateEvent.emit(templateKey);
    }

    public handleClickSubtitle(templateKey: string) {
        this.logger.log(
            'handleClickSubtitle',
            this.trans.getRoutePath(templateKey)
        );
        this.clickTemplateEvent.emit(templateKey);
    }
}
