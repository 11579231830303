/**
 * without template e.g. 'header.titleFieldPlaceholder'
 * with template e.g. 'schedule.header.titleFieldPlaceholder'
 * If now templatet key is provided uses the template that is initialized
 */

import { Pipe, PipeTransform } from "@angular/core"
import { TemplateService } from "../template.service"

@Pipe({
    standalone: true,
    name: 'templateLabel',
})
export class TemplateLabelPipe implements PipeTransform {
    constructor(
        private templateService: TemplateService,
    ) {

    }
    transform(value: string) {
        const valueSplit = value.split('.')
        if (valueSplit.length === 3) {
            return this.templateService.getLabel(valueSplit[1], valueSplit[2], valueSplit[0])
        } else if (valueSplit.length === 2) {
            return this.templateService.getLabel(valueSplit[0], valueSplit[1],)
        } else {
            return value

        }

    }
}
