<landing-page-section-top [templateKey]="templateKey" imageSource="assets/images/lady-4-with-background.png">
</landing-page-section-top>

<div class="whoo-why-whocan-container">
    <why-whocan [templateKey]="templateKey"></why-whocan>
</div>

<div class="whoo-whocan-advantages-container">
    <whocan-advantages [templateKey]="templateKey"></whocan-advantages>
</div>

<div class="whoo-testimonial-standalone-container">
    <testimonial-standalone [templateKey]="templateKey" name="Alexander"
        imageUrl="assets/images/profiles/new/alexander.png">
    </testimonial-standalone>
</div>

<div class="whoo-how-it-works-section-container">
    <how-it-works [templateKey]="templateKey" [showCreatePollButton]="true"
        [bigScreenImages]="howItWorksBigScreenImages" [smallScreenImages]="howItWorksSmallScreenImages">
    </how-it-works>
</div>

<div class="whoo-section-container whoo-poll-templates-container">
    <div class="whoo-poll-templates">
        <h2>{{getLabel('landingPagePollTemplates','headline')}}</h2>
        <poll-templates [hideTemplates]="hideTemplates" [openDialog]="true" [singleColor]="true" appearance="image">
        </poll-templates>
    </div>
</div>

<div class="whoo-founder-statement-container">
    <founder-statement [headline]="getLabel('landingPageFounderStatement','headline')" name='Paul & Kai'
        imgSrc='assets/images/profiles/paul-and-kai.jpeg'
        [paragraph1]="getLabel('landingPageFounderStatement','paragraph1')"
        [paragraph2]="getLabel('landingPageFounderStatement','paragraph2')">
    </founder-statement>
</div>

<div class="whoo-section-container whoo-testimonials">
    <h2>{{getLabel('landingPageTestimonials','headline')}}</h2>
    <testimonial-item [imageRight]="true" name="Lars" imageUrl="assets/images/profiles/new/lars.webp"
        [text]="getLabel('landingPageTestimonials','text-1')" [titel]="getLabel('landingPageTestimonials','title-1')">
    </testimonial-item>
    <testimonial-item [imageRight]="false" name="Maria" imageUrl="assets/images/profiles/new/maria.webp"
        [text]="getLabel('landingPageTestimonials','text-2')" [titel]="getLabel('landingPageTestimonials','title-2')">
    </testimonial-item>
    <testimonial-item [imageRight]="true" name="Michael" imageUrl="assets/images/profiles/new/michael.webp"
        [text]="getLabel('landingPageTestimonials','text-3')" [titel]="getLabel('landingPageTestimonials','title-3')">
    </testimonial-item>
    <testimonial-item [imageRight]="false" name="Kora" imageUrl="assets/images/profiles/new/kora.webp"
        [text]="getLabel('landingPageTestimonials','text-4')" [titel]="getLabel('landingPageTestimonials','title-4')">
    </testimonial-item>
    <testimonial-item [imageRight]="true" name="Magarita" imageUrl="assets/images/profiles/new/magarita.webp"
        [text]="getLabel('landingPageTestimonials','text-5')" [titel]="getLabel('landingPageTestimonials','title-5')">
    </testimonial-item>
</div>