<!---App-context -->
<div class="wrapper" *ngIf="routeContextService.appContext" [ngClass]="appShellService.showFooterInApp?'':'no-footer'">
    <div class="app-container">
        <div *ngIf="!appShellService.getShowContextBar() && ! appShellService.isFullScreen" class="marging-top">
        </div>
        <!--Bars-->
        <sticky-bar>
            <nav-bar *ngIf="!appShellService.getShowContextBar() && !appShellService.isFullScreen"></nav-bar>
            <div *ngIf="appShellService.getShowContextBar()" class="context-bar-container">
                <div class="context-bar ">
                    <context-action-bar> </context-action-bar>
                </div>

            </div>
            <ess-banner *ngIf="appShellService.getShowBanner()"> </ess-banner>
        </sticky-bar>
        <!--Content-->
        <div class="page-container">
            <div class="offline-message-container  whoo-centered-container-fit-content" *ngIf="!online">
                <span class="offline-message" i18n>You are offline!</span>
            </div>
            <!-- router outlet-->
            <router-outlet></router-outlet>
        </div>
        <!--Footer-->
        <div *ngIf="appShellService.showFooterInApp" class="footer-container">
            <footer></footer>
        </div>
    </div>
    <!--Feedback-->
    <feedback-button></feedback-button>
    <!--Cookie Consent-->
    <cookie-consent></cookie-consent>

    <!--Progress Bar-->
    <app-progress-bar *ngIf="authService.isLoading ">
    </app-progress-bar>
</div>
