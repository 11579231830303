import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { TemplateLabelPipe } from '../../../../whoo/core/poll-templates/template-label/template-label.pipe';
import { CreatePollButton } from '../create-poll-button/create-poll.button';
@Component({
    selector: 'whocan-advantages',

    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        RouterModule,
        MatIconModule,
        // Standalone components
        TemplateLabelPipe,

        CreatePollButton,
    ],
    templateUrl: './whocan-advantages.component.html',
    styleUrls: ['./whocan-advantages.component.scss'],
})
export class WhocanAdvantagesComponent {

    @Input() templateKey: string;
    @Input() numberOfAdvantages: number = 5;


    constructor(public responsiveService: ResponsiveService) {
    }
    get labelPrefix() {
        return this.templateKey + '.landingPageAdvantages.'
    }
}
