<div [ngClass]="responsiveService.getBigSmall()">

    <div class="background-container">
        <div class="flex-container">

            <div *ngIf="!responsiveService.isScreenBelowMedium()" class="flex-item logo">
                <img src="assets/images/whocan-white.png" i18n-alt alt="Whocan">
            </div>
            <div class="flex-item">
                <h2 i18n>PLAN EVENTS</h2>
                <ul>
                    <li *ngFor="let category of blogService.getCategories()">
                        <a class="category" (click)="onCategoryClick(category)"
                            [routerLink]="blogService.getCategoryRoutePath(category)" routerLinkActive="active-link"
                            matTooltip="{{category}}">
                            {{category}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="flex-item">
                <h2 i18n>POLLS</h2>
                <ul>
                    <li>
                        <a i18n [routerLink]="trans.getRoutePath('doodleAlternative')">
                            Doodle Alternative
                        </a>
                    </li>
                    <li>
                        <a i18n routerLink="{{trans.getRoutePath('schedule')}}">
                            Meeting Scheduler
                        </a>
                    </li>
                    <li>
                        <a i18n routerLink="{{trans.getRoutePath('participation')}}">
                            Online Registration
                        </a>
                    </li>
                    <li>
                        <a i18n routerLink="{{trans.getRoutePath('invitation')}}">
                            RSVP Invitation
                        </a>
                    </li>
                    <li>
                        <a i18n routerLink="{{trans.getRoutePath('birthdayInvitation')}}">
                            Birthday Invitation
                        </a>
                    </li>
                    <li>
                        <a i18n routerLink="{{trans.getRoutePath('potluck')}}">
                            Organize Potluck
                        </a>
                    </li>
                    <li>
                        <a i18n routerLink="{{trans.getRoutePath('duties')}}">
                            Divide Tasks
                        </a>
                    </li>
                    <li>
                        <a i18n routerLink="{{trans.getRoutePath('preference')}}">
                            Ask Preferences
                        </a>
                    </li>
                </ul>
            </div>
            <div class="flex-item">
                <h2>WHOCAN</h2>
                <ul>
                    <li *ngIf="responsiveService.isScreenSmall()">
                        <a routerLink="{{trans.getRoutePath('chooseLanguage')}}">
                            Languages
                        </a>
                    </li>

                    <li>
                        <a i18n routerLink="{{trans.getRoutePath('about')}}">
                            About us
                        </a>
                    </li>
                    <li>
                        <a i18n routerLink="{{trans.getRoutePath('privacy')}}">
                            Privacy
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/whocan-org/" target="_blank">
                            LinkedIn
                        </a>
                    </li>
                    <li>
                        <a href="https://www.xing.com/pages/whocan" target="_blank">
                            Xing
                        </a>
                    </li>
                </ul>
            </div>
            <div class="flex-item" *ngIf="responsiveService.isScreenBig()">
                <h2>LANGUAGES</h2>
                <ul>
                    <li *ngFor="let language of trans.languages">
                        <a href="/{{language.id}}" [ngClass]="language.id===trans.locale? 'current-language':''">
                            <span> {{language.name}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="version-container">
            <span class="version">Version: {{version}} </span>
        </div>
    </div>
</div>
