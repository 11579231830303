import { Injectable } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { firstValueFrom, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { VoteClass, VoteData } from 'whocan-lib';
import { PathService } from '../../core/path.service';
import firebase from 'firebase/compat/app';
import { EssErrorService } from 'ngx-essentia';
import { OptionsService } from '../../shared/options/options.service';

@Injectable({
    providedIn: 'root'
})
export class VotesService {
    public _voteClassList: VoteClass[] = [];
    private _isLoading: boolean = false;
    constructor(
        private logger: LoggerService,
        private afd: AngularFireDatabase,
        private pathService: PathService,
        private errorService: EssErrorService,
        private optionsService: OptionsService
    ) {
    }

    get voteClassList(): VoteClass[] {
        return this._voteClassList
    }

    public async load() {
        this._isLoading = true
        const votesDataList = await firstValueFrom(this.obvservable)
        votesDataList.forEach(votesData => {
            this._voteClassList.push(new VoteClass(votesData, this.optionsService.optionList.lengthIncludingDeleted))
        })
        this.logger.log('loadVotesData : ', this._voteClassList);
        this._isLoading = false
    }

    public uninitialize() {
        this._voteClassList = []
    }

    get isLoading(): boolean {
        return this._isLoading
    }

    get obvservable(): Observable<VoteData[]> {
        const ref: AngularFireList<VoteData> = this.afd.list(this.pathService.getVotesPath());
        return ref.valueChanges().pipe(
            map(votesDataList => {
                return votesDataList
            }));
    }

    public async getVotes(ownerId: string, pollId: string): Promise<VoteData[]> {
        const ref: AngularFireList<VoteData> = this.afd.list(this.pathService.getVotesPath(ownerId, pollId));
        return firstValueFrom(ref.valueChanges());
    }

    public deleteVotes(ownerId: string, pollId: string) {
        const ref: AngularFireObject<any> = this.afd.object(this.pathService.getVotesPath(ownerId, pollId));
        ref.remove();
        this.logger.log('getVotesPath: ownerId:  ' + ownerId + '; pollId: ' + pollId);
    }

    public persistVote(vote: VoteClass): void {
        try {
            vote.cleanUpBeforePersist()
        } catch (error) {
            this.errorService.newError('Error persisting vote cleanUpBeforePersist: ' + error + JSON.stringify(vote))
        }
        try {
            const userId: string = vote.userId;
            if (!userId) { throw this.errorService.newError('persistVote: userId is empty'); }
            if (!vote.createdAt) { vote.createdAt = firebase.database.ServerValue.TIMESTAMP;; } // if its a new header we need to set the createdAt
            vote.updatedAt = firebase.database.ServerValue.TIMESTAMP;;
            const ref: AngularFireObject<VoteData> = this.afd.object(`${this.pathService.getUserVotePath(userId)}`);
            this.logger.log('persist : ', this.pathService.getUserVotePath(userId), vote);
            ref.update(vote.voteData);
        } catch (error) {
            this.errorService.newError('Error persisting vote ' + error + JSON.stringify(vote))
        }

    }
}
