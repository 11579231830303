import { Component, Input } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { TemplateLabelPipe } from '../../../../whoo/core/poll-templates/template-label/template-label.pipe';
import { HowItWorksItem } from '../how-it-works-item/how-it-works-item';
import { CreatePollButton } from '../create-poll-button/create-poll.button';
import { TemplateService } from '../../../../whoo/core/poll-templates/template.service';

@Component({
    selector: 'how-it-works',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        RouterModule,
        MatIconModule,
        // Standalone components
        TemplateLabelPipe,
        HowItWorksItem,
        CreatePollButton,
    ],

    templateUrl: './how-it-works.component.html',
    styleUrls: ['./how-it-works.component.scss'],
})
export class HowItWorksComponent {
    @Input() showCreatePollButton: boolean = false;
    @Input() templateKey: string;
    @Input() bigScreenImages: string[];
    @Input() smallScreenImages: string[]
    @Input() numberOfItems: number = 4;

    constructor(
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        private templateService: TemplateService

    ) { }
    getBigScreenImage(index: number): string {
        //  this.logger.log('getBigScreenImage', this.templateService.getLabel('landingPageHowItWorks', 'image-1', this.templateKey), this.templateKey)
        if (this.bigScreenImages && this.bigScreenImages[index - 1]) {
            return this.bigScreenImages[index - 1]
        } else { // nozt in use currently
            switch (index - 1) {
                case 0:
                    return this.templateService.getLabel('landingPageHowItWorks', 'image-1', this.templateKey)

                case 1:
                    return this.templateService.getLabel('landingPageHowItWorks', 'image-2', this.templateKey)

                case 2:
                    return this.templateService.getLabel('landingPageHowItWorks', 'image-3', this.templateKey)

                case 3:
                    return this.templateService.getLabel('landingPageHowItWorks', 'image-4', this.templateKey)

                case 4:
                    return this.templateService.getLabel('landingPageHowItWorks', 'image-5', this.templateKey)

                case 5:
                    return this.templateService.getLabel('landingPageHowItWorks', 'image-6')
            }
            return ""

        }

    }

    getSmallScreenImage(index: number): string {
        if (this.smallScreenImages && this.smallScreenImages[index - 1]) {
            return this.smallScreenImages[index - 1]
        } else {
            return null
        }
    }

    get labelPrefix() {
        return this.templateKey + '.landingPageHowItWorks.'
    }
}
