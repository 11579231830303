import { Injectable } from '@angular/core';
import { LOCALE_ID, Inject } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import dicEn from '../../../assets/locale/en/general.en.json';
import dicDe from '../../../assets/locale/de/general.de.json';
import dicFr from '../../../assets/locale/fr/general.fr.json';
import dicEs from '../../../assets/locale/es/general.es.json';
import dicPt from '../../../assets/locale/pt/general.pt.json';
import dicIt from '../../../assets/locale/it/general.it.json';
import { routesSeo as routesSeoEn } from '../../locale/en/routes-seo';
import { routesSeo as routesSeoDe } from '../../locale/de/routes-seo';
import { routesSeo as routesSeoIt } from '../../locale/it/routes-seo';
import { routesSeo as routesSeoEs } from '../../locale/es/routes-seo';
import { routesSeo as routesSeoFr } from '../../locale/fr/routes-seo';
import { routesSeo as routesSeoPt } from '../../locale/pt/routes-seo';

export interface language {
    id: string,
    englishName: string,
    name: string
}
export const languages: language[] = [
    { id: "de", englishName: "German", name: "Deutsch" },
    { id: "en", englishName: "English", name: "English" },
    { id: "es", englishName: "Spanish", name: "Español" },
    { id: "fr", englishName: "French", name: "Français" },
    { id: "it", englishName: "Italian", name: "Italiano" },
    { id: "pt", englishName: "Portugiese", name: "Português" },
]

@Injectable({
    providedIn: 'root'
})
export class TranslationService {
    private fallbackShowKeys = false

    public dic: any = null; // that should be private in future - only the label function should be used
    private routeSeo = null;
    public languages = languages;
    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private logger: LoggerService

    ) {
        this.initLanguage();
    }

    getDic(languageCode: string): any {
        if (languageCode === 'de') {
            return dicDe;
        } else if (languageCode === 'it') {
            return dicIt;
        } else if (languageCode === 'fr') {
            return dicFr;
        } else if (languageCode === 'es') {
            return dicEs;
        } else if (languageCode === 'pt') {
            return dicPt;
        } else {
            return dicEn;
        }
    }

    public label(key1: string, key2: string): string {
        let result = this.dic?.[key1]?.[key2];
        if (result) return result;
        result = dicEn?.[key1]?.[key2]
        if (result) return result
        if (this.fallbackShowKeys) {
            return key1 + '.' + key2
        } else {
            return ""
        }
    }


    public isLanguageImplemented(languageCode: string): boolean {
        const findResult = languages.find(lang => lang.id === languageCode)
        this.logger.log('isLanguageImplemented', languageCode, findResult)
        if (findResult) return true;
        return false
    }

    public getLanguageName(languageCode: string): string {
        const findResult = languages.find(lang => lang.id === languageCode)
        if (findResult) return findResult.name;;
        return ""

    }
    public getLanguageNameInEnglish(languageCode: string) {
        const findResult = languages.find(lang => lang.id === languageCode)
        if (findResult) return findResult.englishName;
        return ""

    }

    private initLanguage() {
        if (this.local === 'de') {
            this.dic = dicDe;
            this.routeSeo = routesSeoDe;
        } else if (this.local === 'pt') {
            this.dic = dicPt;
            this.routeSeo = routesSeoPt;

        } else if (this.local === 'it') {
            this.dic = dicIt;
            this.routeSeo = routesSeoIt;

        } else if (this.local === 'fr') {
            this.dic = dicFr;
            this.routeSeo = routesSeoFr;

        } else if (this.local === 'es') {
            this.dic = dicEs;
            this.routeSeo = routesSeoEs;

        } else {// the default is english
            this.dic = dicEn;
            this.routeSeo = routesSeoEn;
        }
    }



    get local(): string {
        return this.locale.substring(0, 2);
    }

    public getRoutePath(pathId: string): string {
        let returnValue: string = this.routeSeo[pathId];
        if (returnValue === undefined) {
            returnValue = routesSeoEn[pathId];
        }
        if (!returnValue || returnValue === undefined || returnValue.startsWith('/')) {
            return returnValue;
        }
        //    this.logger.log('getRoutePath', returnValue);
        return '/' + returnValue;
    }

    public getShortTitle(id: string) {
        let returnValue = this.routeSeo[id + '.shortTitle'];
        if (returnValue === undefined) {
            returnValue = routesSeoEn[id + '.shortTitle'];
        }
        return returnValue;
    }

    public getSeoTitle(id: string) {
        let returnValue = this.routeSeo[id + '.title'];

        if (returnValue === undefined) {
            returnValue = routesSeoEn[id + '.title'];
        }
        return returnValue;
    }

    public getSeoDescription(id: string) {
        let returnValue = this.routeSeo[id + '.description'];
        if (returnValue === undefined) {
            returnValue = routesSeoEn[id + '.description'];
        }
        return returnValue;

    }

}
