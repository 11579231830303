
import { OptionTypeEnum } from "./option-type.class";
import { TimePeriodClass, TimePeriodData } from "../time-period.class";
import { OptionConfigClass } from "./option-config.class";

/**  Representing the configuration of an option. */
export interface OptionData {
    index?: number; /**should never change, this is the link to the vote; the order in which the option is saved, */
    optionConfigKey?: string /** defines the optionConfig */
    type?: OptionTypeEnum; /** @depricated historically defining TEXT or DATE option, now replaced by the optionConfig*/
    label?: string; /**actually the text titel */
    secondaryAnswerMax?: number;
    timePeriod?: TimePeriodData;
    description?: string;
    maxNumberOfEntries?: number;
    checked?: boolean; /** determines if an option was selected by the user , attribute should not persist*/
    isDeleted?: boolean;
    updatedAt?: any;
    createdAt?: any;
    createdByUid?: string;
    createdByName?: string;
    isCreatedByParticipant?: boolean;
}

export class OptionClass {
    private _optionData: OptionData;

    constructor(optionData: OptionData) {
        if (!optionData) { throw new Error('!optionData') }
        if (!optionData.type) { throw new Error('!optionData.type') }
        if (optionData.type === OptionTypeEnum.date &&
            (!optionData.timePeriod
                || optionData.timePeriod.date == null
                || optionData.timePeriod.startHour == null
                || optionData.timePeriod.endHour == null
            )) {
            throw new Error('!optionData.timePeriod')
        }
        if (!optionData.optionConfigKey) { // for old options that have on configKey
            if (optionData.type === OptionTypeEnum.date) {
                optionData.optionConfigKey = 'date'
            } else {
                optionData.optionConfigKey = 'text'

            }
        }
        this._optionData = optionData
        if (optionData.type === OptionTypeEnum.date) {
            this._optionData.timePeriod = optionData.timePeriod
        }


    }

    get optionData(): OptionData {
        return this._optionData
    }
    set optionData(optionData: OptionData) {
        this._optionData = optionData
    }

    get index(): number | undefined {
        return this._optionData.index;
    }
    set index(value: number | undefined) {
        this._optionData.index = value;
    }

    toString(): string {
        if (this.isOfQuestionTypeDate) return this.timePeriodClass.toString()
        if (this.isOfQuestionTypeText) return this.label
        if (this.isOfQuestionTypeTextAndNumber) return this.secondaryAnswerMax + " " + this.label

    }

    /**
     * contains a reference of the same data hence it can be used to manupulate the daa
     */
    get timePeriodClass(): TimePeriodClass {
        return new TimePeriodClass(this._optionData.timePeriod);
    }

    set timePeriodClass(value: TimePeriodClass) {
        this._optionData.timePeriod = value.timePeriodData;
    }

    public static newOption(optionConfigKey: string, timePeriod?: TimePeriodClass): OptionClass {
        const optionData: OptionData = { optionConfigKey: optionConfigKey };
        const optionConfig = OptionConfigClass.newOptionConfigClass(optionConfigKey)
        if (optionConfig.isOfTypeDate) {
            if (timePeriod == null) {
                timePeriod = new TimePeriodClass(new Date())
            }
            optionData.type = OptionTypeEnum.date
            optionData.timePeriod = timePeriod.timePeriodData
            return new OptionClass(optionData);
        } else if (optionConfig.isOfTypeText) {
            optionData.type = OptionTypeEnum.text
            return new OptionClass(optionData);
        } else if (optionConfig.isOfTypeTextAndNumber) {
            optionData.type = OptionTypeEnum.textAndNumber
            return new OptionClass(optionData);
        } else {
            throw new Error('No question type')
        }
    }


    /**
     *
     * @returns copy by value
     */
    public deepCopy(): OptionClass {
        if (this._optionData.timePeriod) {
            return new OptionClass({ ...this._optionData, timePeriod: { ...this._optionData.timePeriod } })
        } else {
            return new OptionClass({ ...this._optionData })
        }
    }

    public copyAtrributes(option: OptionClass) {
        this.optionConfigKey = option.optionConfigKey
        if (option.label !== undefined) this.label = option.label
        if (option.secondaryAnswerMax !== undefined) this.secondaryAnswerMax = option.secondaryAnswerMax
        if (option.description !== undefined) this.description = option.description
        if (option.maxNumberOfEntries !== undefined) this.maxNumberOfEntries = option.maxNumberOfEntries
        if (option.optionData.timePeriod !== undefined) {
            if (!this.optionData.timePeriod) this.optionData.timePeriod = {}
            if (option.optionData.timePeriod.date !== undefined) this.optionData.timePeriod.date = option.optionData.timePeriod.date
            if (option.optionData.timePeriod.startHour !== undefined) this.optionData.timePeriod.startHour = option.optionData.timePeriod.startHour
            if (option.optionData.timePeriod.endHour !== undefined) this.optionData.timePeriod.endHour = option.optionData.timePeriod.endHour
        }
        if (option.isDeleted !== undefined) this.isDeleted = option.isDeleted
    }

    /**
    * put the text-options first (ordered by optionIndex); then the date-options (ordered by date)
    * @param optionClassA
    * @param optionClassB
    * @returns
    */
    public static standardCompare(optionClassA: OptionClass, optionClassB: OptionClass): 1 | 0 | -1 {
        if (optionClassA.optionConfig.isOfTypeText && optionClassB.optionConfig.isOfTypeText) {
            if (optionClassA.index < optionClassB.index) {
                return -1
            } else {
                return 1
            }
        }
        if (optionClassA.optionConfig.isOfTypeText && optionClassB.optionConfig.isOfTypeDate) {
            return -1
        }
        if (optionClassA.optionConfig.isOfTypeDate && optionClassB.optionConfig.isOfTypeText) {
            return 1
        }
        // at this point both are of type date
        return TimePeriodClass.compareStartDateTime(optionClassA.timePeriodClass.timePeriodData, optionClassB.timePeriodClass.timePeriodData)
    }

    get isOfQuestionTypeDate(): boolean {
        return this.optionConfig.isOfTypeDate
    }

    get isOfQuestionTypeText(): boolean {
        return this.optionConfig.isOfTypeText
    }

    get isOfQuestionTypeTextAndNumber(): boolean {
        return this.optionConfig.isOfTypeTextAndNumber
    }

    get optionConfigKey(): string {
        return this._optionData.optionConfigKey
    }

    set optionConfigKey(value: string) {
        this._optionData.optionConfigKey = value
    }

    get optionConfig(): OptionConfigClass {
        return OptionConfigClass.newOptionConfigClass(this._optionData.optionConfigKey)
    }


    get label(): string | undefined {
        return this._optionData.label;
    }
    set label(value: string | undefined) {
        this._optionData.label = value;
    }

    get secondaryAnswerMax(): number | undefined {
        return this._optionData.secondaryAnswerMax;
    }
    set secondaryAnswerMax(value: number) {
        this._optionData.secondaryAnswerMax = value;
    }

    get description(): string | undefined {
        return this._optionData.description;
    }

    set description(value: string | undefined) {
        this._optionData.description = value;
    }

    get maxNumberOfEntries(): number | undefined {
        return this._optionData.maxNumberOfEntries;
    }

    set maxNumberOfEntries(value: number | undefined) {
        this._optionData.maxNumberOfEntries = value;
    }


    get checked(): boolean | undefined {
        return this._optionData.checked;
    }

    set checked(value: boolean | undefined) {
        this._optionData.checked = value;
    }

    get isDeleted(): boolean | undefined {
        return this._optionData.isDeleted;
    }

    set isDeleted(value: boolean | undefined) {
        this._optionData.isDeleted = value;
    }

    get updatedAt(): any {
        return this._optionData.updatedAt;
    }

    set updatedAt(value: any) {
        this._optionData.updatedAt = value;
    }

    get createdAt(): any {
        return this._optionData.createdAt;
    }

    set createdAt(value: any) {
        this._optionData.createdAt = value;
    }


    public setMarkAsCreatedBy(name: string, uid: string) {
        this._optionData.isCreatedByParticipant = true;
        this._optionData.createdByName = name;
        this._optionData.createdByUid = uid;
    }

    get createdByUid(): string | undefined {
        return this._optionData.createdByUid;
    }

    get createdByName(): string | undefined {
        return this._optionData.createdByName;
    }
    set createdByName(name: string) {
        this._optionData.createdByName = name;
    }


    get isCreatedByParticipant(): boolean {
        if (this._optionData?.isCreatedByParticipant) return this._optionData?.isCreatedByParticipant;
        return false
    }




}
