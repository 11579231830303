<div [ngClass]="responsiveService.getBigSmall()">
    <div class="poll-templates-container" [ngClass]="singleColor?'singleColor':''">
        <ng-container *ngFor="let templateKey of templateService.getTemplateKeys()">
            <span *ngIf="showTemplate(templateKey)" class="poll-template" (click)="handleClickTemplate(templateKey)">
                <div class="button-container">
                    <template-icon [templateKey]="templateKey"></template-icon>
                </div>
            </span>

        </ng-container>
    </div>
    <div *ngIf="showAdvancedLink" class="whoo-centered-container-fit-content  additional-links">
        <div class="advanced-link"> <span i18n> It's none of this? </span> &nbsp;<a (click)="handleClickTemplate()"
                i18n>
                Plan Events with our advanced poll</a>.</div>
    </div>
</div>