import { Component, Inject, LOCALE_ID } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { BlogGeneralService } from '../../blog/blog-general.service';
import { ResponsiveService } from '../../shared/services/responsive.service';
import { TranslationService } from '../../shared/services/translation.service';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'footer',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        MatTooltipModule
    ],
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    constructor(
        @Inject(LOCALE_ID) public locale: string,

        private router: Router,
        public responsiveService: ResponsiveService,
        public trans: TranslationService,
        public blogService: BlogGeneralService
    ) { }

    getLocal() {
        return this.locale.substring(0, 2);
    }
    get version() {
        return environment.appVersion;
    }
    public onCategoryClick(category: string) {
        this.router.navigate([this.blogService.getCategoryRoutePath(category)]);
    }

}
