
import { Injectable } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { LOCALE_ID, Inject } from '@angular/core';
import { CurrentPollService } from './current-poll.service';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { TranslationService } from '../../shared/services/translation.service';
import { environment } from 'projects/whocan-app/src/environments/environment';
import { paths } from 'functions-lib';
import { UserService } from '../../user/user.service';
import { AuthService } from '../../user/auth.service';
import { EssErrorService } from 'ngx-essentia';



@Injectable({ providedIn: 'root' })
export class NavigationService {
    private history: string[] = [];
    constructor(
        private logger: LoggerService,
        @Inject(LOCALE_ID) public locale: string,
        private currentPollService: CurrentPollService,
        private trans: TranslationService,
        private router: Router,
        private location: Location,
        private userService: UserService,
        private authService: AuthService,
        private errorService: EssErrorService

    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.logger.log('instanceof NavigationEnd', event.urlAfterRedirects, this.history);
                this.history.push(event.urlAfterRedirects);
            }
        });
    }

    get isBlogBlogPost() {
        this.logger.log('isBlogPost', this.history[this.history.length - 1])
        if (this.history[this.history.length - 1] && this.history[this.history.length - 1].indexOf('/blog/') !== -1) {
            this.logger.log('isBlogPost', 'true')
            return true
        }
        this.logger.log('isBlogPost', 'false')
        return false;
    }

    // getPath
    // getPath

    private getPathHome() {
        return '/' + this.trans.getRoutePath('homeTemplates')
    }

    private getPathCreatePoll() {
        return '/' + this.trans.getRoutePath('createPoll')
    }

    private getPathMyPolls() {
        return '/' + this.trans.getRoutePath('myPolls')
    }

    private getPathEditPoll(step: string, ownerId?: string, pollId?: string) {
        return this.trans.getRoutePath('editPoll')
            + '/' + step + '/' + this.getOwnerSlashPollId(ownerId, pollId);
    }

    private getPathVotePreview(ownerId?: string, pollId?: string, voteUserId?: string) {
        return this.trans.getRoutePath('votePreview')
            + '/' + this.getOwnerSlashPollSlashVoteUserId(ownerId, pollId, voteUserId);
    }

    private getPathVotePreviewResponse(ownerId?: string, pollId?: string, voteUserId?: string) {
        return this.trans.getRoutePath('votePreviewResponse')
            + '/' + this.getOwnerSlashPollSlashVoteUserId(ownerId, pollId, voteUserId);
    }

    private getPathVote(ownerId?: string, pollId?: string, voteUserId?: string) {
        return this.trans.getRoutePath('vote')
            + '/' + this.getOwnerSlashPollSlashVoteUserId(ownerId, pollId, voteUserId);
    }

    /**
     * not specific to a user vote, this is what we need to share a
     */
    private getPathVoteGeneric(ownerId?: string, pollId?: string, voteUserId?: string) {
        return this.trans.getRoutePath('vote')
            + '/' + this.getOwnerSlashPollId(ownerId, pollId);
    }

    private getPathVoteResponse(ownerId?: string, pollId?: string, voteUserId?: string) {
        return this.trans.getRoutePath('voteResponse')
            + '/' + this.getOwnerSlashPollSlashVoteUserId(ownerId, pollId, voteUserId);
    }

    public getPathPollResponses(ownerId?: string, pollId?: string) {
        return this.trans.getRoutePath('pollResponses')
            + '/' + this.getOwnerSlashPollId(ownerId, pollId);
    }

    // navigate
    // navigate

    public navigate(routePath: string) {
        this.router.navigate([routePath]);
    }

    public navigateHome() {
        this.router.navigate([this.getPathHome()]);
    }
    public navigateMyPolls() {
        this.router.navigate([this.getPathMyPolls()]);
    }

    public navigatePollResponses(ownerId?: string, pollId?: string) {
        this.router.navigate([this.getPathPollResponses(ownerId, pollId)]);
    }

    public navigateVote(ownerId?: string, pollId?: string, voteUserId?: string) {
        this.router.navigate([this.getPathVote(ownerId, pollId, voteUserId)]);
    }

    public navigateVotePreview(ownerId?: string, pollId?: string, voteUserId?: string, sidenavIsOpen?: boolean) {
        if (sidenavIsOpen === true) {
            this.router.navigate([this.getPathVotePreview(ownerId, pollId, voteUserId)], { queryParams: { edit: 'true' } });
        } else {
            this.router.navigate([this.getPathVotePreview(ownerId, pollId, voteUserId)]);
        }
        return;
    }

    public navigateVotePreviewResponse(ownerId?: string, pollId?: string, voteUserId?: string) {
        this.router.navigate([this.getPathVotePreviewResponse(ownerId, pollId, voteUserId)]);
    }

    public navigateVoteResponse(ownerId?: string, pollId?: string, voteUserId?: string) {
        this.router.navigate([this.getPathVoteResponse(ownerId, pollId, voteUserId)]);
    }

    public navigateEditPoll(step: string, ownerId?: string, pollId?: string) {
        this.router.navigate([this.getPathEditPoll(step, ownerId, pollId)]);
    }

    public navigateCreatePoll(pollTitle?: string, template?: string, themeKey?: string) {
        let routePath = this.getPathCreatePoll();
        if (pollTitle) {
            routePath = routePath + '/' + encodeURIComponent(pollTitle);
        }
        if (template) {
            this.logger.log('navigateCreatePoll', pollTitle, template, themeKey, routePath);
            this.router.navigate([routePath], { queryParams: { template: template, theme: themeKey } });
        } else {
            this.router.navigate([routePath]);
        }
    }

    /**
     * navigate backward in broser history if it is inside of the the app
     * @returns true if the navigation back was possible, false otherwise
     */
    public navigateBack(): boolean {
        this.logger.log('navigateBack', this.history);
        this.history.pop();
        if (this.history.length > 0) {
            this.logger.log('navigateBack this.history.length > 0)', this.history[this.history.length - 1]);
            //  this.navigate(this.history[this.history.length - 1]);
            this.location.back();
            this.logger.log('this.location.back()', this.history);
            return true;
        } else {
            return false;
        }
    }



    // getURL
    // getURL

    // Return the domain depending on evironment (prod or test) including locale
    public getUrlHost(): string {
        if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
            return "http://" + location.hostname + ":" + location.port
        }

        if (this.locale && this.locale !== '' && this.locale !== 'en-US') {
            return environment.host_url + '/' + this.locale;
        } else {
            return environment.host_url;
        }
    }

    public getUrlPollResponses(ownerId?: string, pollId?: string) {
        return this.getUrlHost() +
            this.getPathPollResponses(ownerId, pollId)
    }

    public getUrlEditPoll(ownerId?: string, pollId?: string) {
        return this.getUrlHost() + this.getPathEditPoll('0', ownerId, pollId);
    }

    public getUrlVotePreview(ownerId?: string, pollId?: string) {
        return this.getUrlHost() + this.getPathVotePreview(ownerId, pollId)
    }

    public getUrlVote(ownerId?: string, pollId?: string) {
        return this.getUrlHost() + this.getPathVote(ownerId, pollId)
    }

    public getUrlSharePoll(ownerId?: string, pollId?: string) {
        return this.getUrlHost() + this.getPathVoteGeneric(ownerId, pollId)
    }

    // getUrlAssignAccess
    // getUrlAssignAccess
    public getUrlAssignAccessMyPolls(): string {
        const uid = this.authService.uid
        const secretKey = this.userService.getSecretKey();
        const redirectRoute = this.getPathMyPolls()
        return this.composeUrlAssignAccess(uid, secretKey, redirectRoute)
    }

    public getUrlAssignAccessVote(): string {
        const uid = this.authService.uid
        const secretKey = this.userService.getSecretKey();
        const redirectRoute = this.getPathVote();
        return this.composeUrlAssignAccess(uid, secretKey, redirectRoute)
    }


    public getUrlAssignAccessPollResponses(): string {
        if (!this.currentPollService.isOwner) {

            throw (this.errorService.newError('getUrlAssginAccessPollResponses, user is not the owner: ' + this.authService.uid,))
        }
        const uid = this.currentPollService.ownerId //  need to use the ownerId for the case that the user inherits ownership alread from someone else
        const secretKey = this.userService.getSecretKey(uid);
        const redirectRoute = this.getPathPollResponses();
        return this.composeUrlAssignAccess(uid, secretKey, redirectRoute)
    }

    private composeUrlAssignAccess(uid: string, key: string, redirectRoute: string
    ) {
        const tree = this.router.createUrlTree(['user/assign-access'], { queryParams: { uid: uid, key: key, route: redirectRoute } });
        return this.getUrlHost() + tree
    }

    /**
     * Set the url in the broswer without triggering any component to reinit or te router service to trigger an event
     * @param step
     * @param ownerId
     * @param pollId
     */
    public goEditPoll(step: string, ownerId?: string, pollId?: string) {
        const routePath = this.getPathEditPoll(step, ownerId, pollId);
        this.location.go(routePath);
    }


    public openWindowLanguage(langCode: string) {
        const url = environment.host_url + '/' + langCode + this.history[this.history.length - 1]
        this.logger.log("navigateLanguage", url)
        window.open(url, '_self');
    }

    public openPollMatrixFullScreen() {
        this.logger.log('openPollMatrixInNewWindow', this.getUrlPollMatrix());
        window.open(this.getUrlPollMatrix(), '_self');
    }


    public getUrlPollMatrix(ownerId?: string, pollId?: string) {
        if ((ownerId && pollId) || this.currentPollService.isInitialized) {
            return this.getUrlHost() + this.trans.getRoutePath('pollMatrix') + '/'
                + this.getOwnerSlashPollId(ownerId, pollId) + '?full-screen';
        } else {
            return '';
        }

    }



    public get functionsUrlPaypalStart(): string {
        return environment.firebase.functionsUrl + "/" + paths.functions.paypalStart
    }

    public navigatePayment() {
        this.logger.log('navigatePayment: ');
        this.router.navigate(['/user/payment']);
    }

    private getOwnerSlashPollId(ownerId?: string, pollId?: string): string {
        this.logger.log('getOwnerSlashPollId: ', ownerId, pollId);
        return this.currentPollService.getOwnerId(ownerId) + '/' + this.currentPollService.getPollId(pollId);
    }

    private getOwnerSlashPollSlashVoteUserId(ownerId?: string, pollId?: string, userId?: string): string {
        return this.getOwnerSlashPollId(ownerId, pollId) + '/' + this.currentPollService.getVoteUserId(userId);
    }
}
