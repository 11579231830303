<!---Navbar-->
<div class="nav-bar-container outer-bar">
    <div class="nav-bar inner-bar">
        <div class="component-container ">
            <!-- left -->
            <div class="left-container">
                <!--logo-->

                <logo-component></logo-component>

                <!--my polls-->
                <div *ngIf="responsiveService.isScreenBig()" class="navigation-links">
                    <a *ngIf="authService.isLoggedIn" routerLink="{{trans.getRoutePath('myPolls')}}"
                        routerLinkActive="active-link" class="navigation-link my-polls" i18n-matTooltip
                        matTooltip="List of your events" i18n>
                        My events
                    </a>
                </div>
            </div>
            <!--blog-->
            <div *ngIf="!authService.isLoggedIn && responsiveService.isScreenBig()" class="middle slogan">
                <a routerLink="{{trans.getRoutePath('blog')}}" routerLinkActive="active-link" class="" i18n-matTooltip
                    matTooltip="Plan events together - Blog" i18n>
                    Plan events together
                </a>
            </div>

            <!-- right -->
            <div class="right-container">
                <nav-create-poll-button></nav-create-poll-button>
                <nav-menu-button></nav-menu-button>
            </div>
        </div>
        <div class="ellipse-container-container">
            <div class="ellipse-container">
                <div class="ellipse">
                </div>
            </div>
        </div>
    </div>

</div>