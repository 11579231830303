import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../../shared/services/responsive.service';

import { TemplateService } from '../../../core/poll-templates/template.service';
import { TemplateLabelPipe } from '../../../core/poll-templates/template-label/template-label.pipe';
import { OptionConfigClass } from 'whocan-lib';
import { MatTooltipModule } from '@angular/material/tooltip';


@Component({
    selector: 'add-option-button',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        TemplateLabelPipe,

    ],
    templateUrl: './add-option-button.component.html',
    styleUrls: ['./add-option-button.component.scss'],
})
export class AddOptionButton {
    @Input() appearance: 'stroked' | 'standard' | 'fab' | 'icon' | 'fill' = 'standard';
    @Input() color = 'primary'
    @Input() persist: boolean = false;
    @Input() filterAllowToAddOptions: boolean = false;
    @Output() onAddOption = new EventEmitter<string>();

    constructor(
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        public templateService: TemplateService,
    ) { }

    addOption(optionConfigKey: string) {
        this.logger.log('addOption', optionConfigKey)
        this.onAddOption.emit(optionConfigKey);
    }

    getIcon(optionConfigKey: string) {
        return OptionConfigClass.newOptionConfigClass(optionConfigKey).icon
    }
}

