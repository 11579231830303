<div [ngClass]="responsiveService.getBigSmall()">
    <div class="component-container">
        <h3 i18n *ngIf="showHeadline">Invite participants using one of the below channels</h3>
        <div class="flex-container">
            <a href="{{getWhatsappHref()}}" *ngIf="responsiveService.isScreenSmall()" class="channel">
                <button mat-button>
                    <img src="assets/images/whatsapp_icon.png" height="22" class="whatsapp-icon">
                    <div class="button-label">
                        WhatsApp
                    </div>
                </button>
            </a>
            <a href="{{getEmailHref()}}" class="channel">
                <button mat-button>
                    <div>
                        <mat-icon>mail_outline</mat-icon>
                    </div>
                    <div class="button-label" i18n>
                        Send e-mail
                    </div>
                </button>
            </a>
            <button mat-button i18n-matTooltip matTooltip="Copy link to clipboard" (click)="copyToClipboard()"
                class="channel">
                <div> <mat-icon>link</mat-icon></div>
                <div class="button-label" i18n>
                    Copy Link
                </div>
            </button>
        </div>
    </div>
</div>