<language-check></language-check>

<!---NOOO App-context -->
<div class="wrapper" *ngIf="!routeContextService.appContext">
    <div class="app-container">
        <div class="marging-top">
        </div>
        <!---Navbar-->
        <sticky-bar>
            <nav-bar></nav-bar>
        </sticky-bar>
        <!--Content-->
        <div class="page-container">
            <!-- router outlet-->
            <router-outlet></router-outlet>
        </div>
        <!--Footer-->
        <div class="footer-container">
            <footer></footer>
        </div>
    </div>
    <!--Feedback-->
    <feedback-button></feedback-button>
    <!--Cookie Consent-->
    <cookie-consent></cookie-consent>
</div>
<!---App-context -->
<div class="wrapper" *ngIf="routeContextService.appContext" class='no-footer'>
    <whoo-app></whoo-app>
</div>
