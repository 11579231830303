import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../shared/services/responsive.service';
import { TemplateLabelPipe } from '../../core/poll-templates/template-label/template-label.pipe';
import { TemplateService } from '../../core/poll-templates/template.service';

@Component({
    selector: 'template-icon',
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        TemplateLabelPipe
    ],
    templateUrl: './template-icon.component.html',
    styleUrls: ['./template-icon.component.scss'],
})
export class TemplateIconComponent implements OnInit {
    @Input() templateKey: string;

    constructor(
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        public templateService: TemplateService
    ) { }

    ngOnInit() { }

}
