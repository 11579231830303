import {
    Component,
    OnInit,
} from '@angular/core';

import { EssDialogService, EssSoftwareUpdateService } from 'ngx-essentia';
import { AuthService } from '../../user/auth.service';
import { ResponsiveService } from '../../shared/services/responsive.service';
import { RouteContextService } from '../../shared/services/route-context.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { UserService } from '../../user/user.service';
import { CurrentPollService } from '../core/current-poll.service';
import { PollResultsService } from '../core/poll-results.service';
import { AppShellService } from '../../app-shell/nav/nav.service';
import { CommonModule } from '@angular/common';
import { NavBarComponent } from '../../app-shell/nav/nav-bar/nav-bar.component';
import { ContextActionBarComponent } from '../shared/context-action-bar/context-action-bar.component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { ProgressBarComponent } from '../../shared/progress-bar/progress-bar.component';
import { ConnectionService } from '../../shared/services/connection.service';
import { UIMesageService } from '../../shared/services/ui-message.service';
import { TranslationService } from '../../shared/services/translation.service';
import { FeedbackButtonComponent } from '../../shared/feedback/feedback-button.component';
import { StickyBarComponent } from '../../shared/sticky-bar/sticky-bar.component';
import { FooterComponent } from '../../app-shell/footer/footer.component';
import { CookieConsentComponent } from '../../app-shell/shared/cookie-consent/cookie-consent.component';
import { BannerComponent } from '../../shared/banner/banner.component';
import { BannerService } from '../../shared/banner/banner.service';
import { AnonymousBanner } from '../../shared/banner/anonymous-banner.class';
import { TestBanner } from '../../shared/banner/test-banner.class';
import { NavigationService } from '../core/navigation.service';




@Component({
    selector: 'whoo-app',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,

        MatIconModule,

        NavBarComponent,
        ProgressBarComponent,
        ContextActionBarComponent,
        FeedbackButtonComponent,
        StickyBarComponent,
        FooterComponent,
        CookieConsentComponent,
        BannerComponent,
    ],
    templateUrl: './whoo-app.component.html',
    styleUrls: ['./../../app.component.scss'],
})
export class WhooAppComponent implements OnInit {
    public online: boolean = false;
    constructor(
        public authService: AuthService,
        public responsiveService: ResponsiveService,
        public routeContextService: RouteContextService,
        private logger: LoggerService,
        public userService: UserService,
        public currentPollService: CurrentPollService,
        public pollResultsService: PollResultsService,
        public appShellService: AppShellService,
        private trans: TranslationService,
        private connection: ConnectionService,
        public uiMessage: UIMesageService,
        private activatedRoute: ActivatedRoute,
        private bannerService: BannerService,
        public dialogService: EssDialogService,
        private navigationService: NavigationService,

    ) { }
    ngOnInit() {
        const ownerId = this.activatedRoute.snapshot.params['ownerId'];
        const pollId = this.activatedRoute.snapshot.params['pollId'];
        let voteUserId = this.activatedRoute.snapshot.params['voteUserId'];
        if (!voteUserId) { voteUserId = this.authService.uid }
        if (ownerId && pollId) this.currentPollService.setCurrentPoll(ownerId, pollId, voteUserId) // so we can determine early enough if the user is a polOwner and dispaly the contextbar accordingly
        this.observeConnection()
        //   this.bannerService.registerBanner(new TestBanner(this.logger, this.uiMessage, true, 10, 'test banner prio 10 message ', 'priority_high'),)
        this.bannerService.registerBanner(new AnonymousBanner(this.logger, this.authService, this.trans, this.dialogService, this.navigationService))
        //  this.bannerService.registerBanner(new TestBanner(this.logger, this.uiMessage, false, 100, 'test banner prio 100 message '))
    }

    private observeConnection(): void {
        this.connection.start();
        this.connection.behaviorSubjectObservable$.subscribe((online) => {
            this.online = online;
            if (!this.online) {
                this.uiMessage.warning(this.trans.dic.offlineMessage);
            } else {
                this.uiMessage.dismiss();
            }
        });
    }



}
