import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { TopBackgroundComponent } from '../top-background/top-background.component';
import { PollTemplatesComponent } from '../../../../whoo/shared/poll-templates/poll-templates.component';
import { TestemonialItem } from '../testimonial-item/testimonial-item';
import { CreatePollButton } from '../create-poll-button/create-poll.button';
import { TemplateKey } from '../../../../whoo/core/poll-templates/template.configuration';
import { TemplateService } from '../../../../whoo/core/poll-templates/template.service';
import { TemplateLabelPipe } from '../../../../whoo/core/poll-templates/template-label/template-label.pipe';


@Component({
    templateUrl: './section-top.component.html',

    selector: 'landing-page-section-top',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        RouterModule,

        // Standalone components
        CreatePollButton,
        TestemonialItem,
        PollTemplatesComponent,
        TopBackgroundComponent,
        TemplateLabelPipe

    ],
    styleUrls: ['./section-top.component.scss'],
})
export class SectionTopComponent {
    @Input() templateKey: string;
    @Input() labelKey = "landingPageSectionTop"
    @Input() imageSource = "assets/images/lady-blue-shirt-pointing2.webp"
    @Input() useStandardContent = true;

    constructor(
        public responsiveService: ResponsiveService,
        public templateService: TemplateService
    ) { }

    get labelPrefix() {
        return this.templateKey + '.' + this.labelKey + '.'
    }

}
