import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { EssDialogService } from 'ngx-essentia';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../shared/services/responsive.service';
import { RouteContextService } from '../../../shared/services/route-context.service';
import { TranslationService } from '../../../shared/services/translation.service';
import { UIMesageService } from '../../../shared/services/ui-message.service';
import { UserService } from '../../../user/user.service';
import { SharePollDialogComponent } from '../share-poll/share-poll-dialog/share-poll-dialog.component';
import { TemplateService } from '../../core/poll-templates/template.service';
import { CurrentPollService } from '../../core/current-poll.service';
import { NavigationService } from '../../core/navigation.service';
import { PathService } from '../../core/path.service';
import { PollService } from '../../core/poll.service';
import { PollResultsService } from '../../core/poll-results.service';
import { AuthService } from '../../../user/auth.service';
import { GetAccessDialogComponent } from '../get-poll-access/accees-dialog/access-dialog.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { BdcWalkModule } from 'bdc-walkthrough';
import { TemplateLabelPipe } from '../../core/poll-templates/template-label/template-label.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ICalService } from '../i-cal/ical.service';
import { ContextActionBarService } from './context-action-bar.service';
import { AddOptionButton } from '../options/add-option-button/add-option-button.component';

@Component({
    selector: 'context-action-bar',
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        BdcWalkModule,
        TemplateLabelPipe,
        MatTooltipModule,
        AddOptionButton
    ],
    templateUrl: './context-action-bar.component.html',
    styleUrls: ['./context-action-bar.component.scss'],
    //  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ContextActionBarComponent implements OnInit {
    constructor(
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        public dialog: MatDialog,
        public pollResultsService: PollResultsService,
        public pathService: PathService,
        public pollService: PollService,
        public routeContextService: RouteContextService,
        public userService: UserService,
        public currentPollService: CurrentPollService,
        public navigationService: NavigationService,
        public trans: TranslationService,
        public responsiveService: ResponsiveService,
        private dialogService: EssDialogService,
        private clipboard: Clipboard,
        public authService: AuthService,
        public icalService: ICalService,
        public templateService: TemplateService,
        public contextActionBarService: ContextActionBarService

    ) {
    }

    ngOnInit(): void {
        this.logger.log('onInit', this.routeContextService.id);
    }


    /** navigate backwards in the app history if possible
     * potherwaise nvigate to myPolls
     *
     */
    public navigateBack() {
        //   this.navigationService.navigateMyPolls();
        if (!this.navigationService.navigateBack()) {
            this.navigationService.navigateMyPolls();
        }

    }


    handleClickCalendar() {
        if (this.icalService.hasPollDateEvents) {
            this.icalService.downloadAllEvents();
        } else {
            this.logger.log('no dates')
            import(
                '../../../shared/ok-cancel-dialog/ok-cancel-dialog.component'
            ).then((m) => {
                const dialogRef = this.dialogService.openSmall(
                    m.OkCancelDialogComponent,
                    {
                        title: this.templateService.getLabel('iCal', 'noEventsDialogTitle'),
                        description3: this.templateService.getLabel('iCal', 'noEventsDialogMessage'),
                        okLabel: this.templateService.getLabel('iCal', 'noEventsDialogOkLabel'),
                    }
                );
            });
        }
    }

    public sharePoll(): void {
        const urlToShare: string = this.navigationService.getUrlSharePoll();
        this.dialogService.openSmall(SharePollDialogComponent, { pollTitle: '', pollUrl: urlToShare });
    }

    public shareResult(): void {
        this.clipboard.copy(this.navigationService?.getUrlPollMatrix());
        this.uiMessage.success(this.trans.dic.poll.urlCopied);

    }

    public async openPollOverview() {
        this.navigationService.navigatePollResponses();
    }

    public async editPoll() {
        this.logger.log('editPoll');
        this.navigationService.navigateVotePreview(this.currentPollService.ownerId, this.currentPollService.pollId, this.currentPollService.voteUserId, true);
    }

    public async openVotePage() {
        this.navigationService.navigateVotePreview();
    }

    public async deletePoll() {
        const { OkCancelDialogComponent } = await import(
            '../../../shared/ok-cancel-dialog/ok-cancel-dialog.component'
        );
        this.logger.log('deletePoll: ');
        const dialogRef = this.dialogService.openSmall(OkCancelDialogComponent,
            {
                title: 'Delete Poll',
                description1: '',
                description3: 'Do you want to continue?',
                okLabel: 'Delete',
                cancelLabel: 'Cancel'
            });

        dialogRef.afterClosed().subscribe(async result => {
            console.log('The dialog was closed');
            if (result) {
                this.logger.log('deletePoll ');
                this.navigationService.navigateMyPolls();
                await this.pollService.deletePoll(this.currentPollService.ownerId, this.currentPollService.pollId);
            } else {
                this.logger.log('Canceled');
            }
        });
    }


    public getAccessDialog(): void {
        this.dialogService.open(GetAccessDialogComponent, null);
    }

}
