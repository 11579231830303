/**
 * This is the same file for all languages except english
 * Why we need these copies is because it's pulling in the dictionary.json in the same folder which is lanugage dependend
 */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
import { environment } from 'projects/whocan-app/src/environments/environment';
import dic from '../../../assets/locale/de/general.de.json';

import pollTemplatesDic from '../../../assets/locale/de/templates.dic.de.json';
const standardTitle = dic.seo.standardTitle + ' | ' + environment.brandName;
const descriptionPostfix = dic.seo.descriptionPostfix;
const standardDescription = dic.seo.standardDescription + descriptionPostfix;
const seperator = " - ";
export const routesSeo = {
    "homeTemplates": dic.seo.routes.homeTemplates,
    "homeTemplates.title": standardTitle,
    "homeTemplates.description": standardDescription,

    "schedule": dic.seo.routes.schedule,
    "schedule.title": environment.brandName + ": " + pollTemplatesDic.schedule.home.headLine1,
    "schedule.description": pollTemplatesDic.schedule.home.headLine2 + descriptionPostfix,

    "doodleAlternative": dic.seo.routes.doodleAlternative,
    "doodleAlternative.title": pollTemplatesDic.doodleAlternative.home.headLine1,
    "doodleAlternative.description": pollTemplatesDic.doodleAlternative.home.headLine2 + descriptionPostfix,

    "participation": dic.seo.routes.participation,
    "participation.title": environment.brandName + ": " + pollTemplatesDic.participation.home.headLine1,
    "participation.description": pollTemplatesDic.participation.home.headLine2 + descriptionPostfix,

    "invitation": dic.seo.routes.invitation,
    "invitation.title": environment.brandName + ": " + pollTemplatesDic.invitation.home.headLine1,
    "invitation.description": pollTemplatesDic.invitation.home.headLine2 + descriptionPostfix,

    "birthdayInvitation": dic.seo.routes.birthdayInvitation,
    "birthdayInvitation.title": environment.brandName + ": " + pollTemplatesDic.birthdayInvitation.home.headLine1,
    "birthdayInvitation.description": pollTemplatesDic.birthdayInvitation.home.headLine2 + descriptionPostfix,

    "duties": dic.seo.routes.duties,
    "duties.title": environment.brandName + ": " + pollTemplatesDic.duties.home.headLine1,
    "duties.description": pollTemplatesDic.duties.home.headLine2 + descriptionPostfix,

    "dutiesDate": dic.seo.routes.dutiesDate,
    "dutiesDate.title": environment.brandName + ": " + pollTemplatesDic.duties.home.headLine1,
    "dutiesDate.description": pollTemplatesDic.duties.home.headLine2 + descriptionPostfix,

    "potluck": dic.seo.routes.potluck,
    "potluck.title": environment.brandName + ": " + pollTemplatesDic.potluck.home.headLine1,
    "potluck.description": pollTemplatesDic.potluck.home.headLine2 + descriptionPostfix,

    "preference": dic.seo.routes.preference,
    "preference.title": environment.brandName + ": " + pollTemplatesDic.preference.home.headLine1,
    "preference.description": pollTemplatesDic.preference.home.headLine2 + descriptionPostfix,

    "blog.title": standardTitle + seperator + dic.seo.title.blog,
    "blog.description": dic.seo.description.blog + seperator + standardDescription,

    "404": dic.seo.routes[404],
    "404.title": standardTitle + seperator + dic.seo.title[404],
    "404.shortTitle": dic.seo.title[404],

    "about.shortTitle": dic.seo.title.about,
    "about.title": standardTitle + seperator + dic.seo.title.about,
    "about.description": dic.seo.description.about + seperator + standardDescription,

    "feedback.shortTitle": dic.seo.title.feedback,
    "feedback.title": standardTitle + seperator + dic.seo.title.feedback,
    "feedback.description": dic.seo.description.feedback + seperator + standardDescription,

    "chooseLanguage.title": standardTitle + seperator + dic.seo.title.chooseLanguage,
    "chooseLanguage.description": dic.seo.description.chooseLanguage + seperator + standardDescription,

    "privacy.title": standardTitle + seperator + dic.seo.title.privacy,
    "privacy.description": dic.seo.description.privacy + seperator + standardDescription,

    "myPolls.title": standardTitle + seperator + dic.seo.title.myPolls,

    "createPoll.title": standardTitle + seperator + dic.seo.title.createPoll,
    "createPoll.shortTitle": dic.seo.title.createPoll,

    "editPoll.title": standardTitle + seperator + dic.seo.title.editPoll,
    "editPoll.shortTitle": dic.seo.title.editPoll,

    "pollResponses.title": standardTitle + seperator + dic.seo.title.pollResponses,
    "pollResponses.shortTitle": dic.seo.title.pollResponses,

    "pollMatrix.title": standardTitle + seperator + dic.seo.title.pollMatrix,
    "pollMatrix.shortTitle": dic.seo.title.pollMatrix,

    "vote.title": standardTitle + seperator + dic.seo.title.vote,
    "vote.shortTitle": dic.seo.title.vote,

    "voteResponse.title": standardTitle + seperator + dic.seo.title.voteResponse,
    "voteResponse.shortTitle": dic.seo.title.voteResponse,

    "votePreview.title": standardTitle + seperator + dic.seo.title.vote,
    "votePreview.shortTitle": dic.seo.title.vote,

    "votePreviewResponse.title": standardTitle + seperator + dic.seo.title.voteResponse,
    "votePreviewResponse.shortTitle": dic.seo.title.voteResponse,

};
