import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { TemplateLabelPipe } from '../../../../whoo/core/poll-templates/template-label/template-label.pipe';
import { TemplateService } from '../../../../whoo/core/poll-templates/template.service';

@Component({
    selector: 'testimonial-standalone',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,

        TemplateLabelPipe,
    ],
    templateUrl: './testimonial-standalone.html',
    styleUrls: ['./testimonial-standalone.scss'],
})
export class TestemonialStandalone {
    @Input() name: string;
    @Input() imageUrl: string;
    @Input() text: string;
    @Input() titel: string;
    @Input() templateKey: string;
    private templateLabelKey = "landingPageTestimonial"

    constructor(
        public responsiveService: ResponsiveService,
        private templateService: TemplateService,

    ) { }




    getText() {
        if (this.text) return this.text
        return this.templateService.getLabel(this.templateLabelKey, 'text', this.templateKey)
    }
    getTitle() {
        if (this.titel) return this.titel
        return this.templateService.getLabel(this.templateLabelKey, 'titel', this.templateKey)
    }
    getName() {
        if (this.name) return this.name
        return this.templateService.getLabel(this.templateLabelKey, 'name', this.templateKey)
    }

}
