import { Injectable } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { OptionData, OptionListData } from 'whocan-lib';
import { AuthService } from '../../../user/auth.service';
import { EssErrorService } from 'ngx-essentia';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { PathService } from '../../core/path.service';
import { OptionClass, OptionList } from 'whocan-lib';
import firebase from 'firebase/compat/app';


@Injectable({
    providedIn: 'root'
})

export class OptionsService {
    private _optionList: OptionList
    public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(
        private logger: LoggerService,
        private authService: AuthService,
        private errorService: EssErrorService,
        private afd: AngularFireDatabase,
        private pathService: PathService,

    ) {
        this._optionList = new OptionList([])
    }

    public async load() {
        this.isLoading$.next(true);
        let optionsData: OptionData[] | OptionListData = await await (firstValueFrom(this.observable))
        this._optionList = new OptionList(optionsData)
        this.logger.log('load optionlist: ', this._optionList)
        this.isLoading$.next(false);
    }



    public uninitialize() {
        this._optionList.uninitialize()
        this._optionList = new OptionList([])
    }

    public get optionList(): OptionList {
        return this._optionList
    }

    public set optionList(value: OptionList) {
        this._optionList = value
    }

    get observable(): Observable<OptionData[]> {
        this.logger.log('optionsObservable');
        const ref: AngularFireObject<(OptionData[])> = this.afd.object(this.pathService.getOptionsPath());
        return ref.valueChanges().pipe(
            map(data => {
                this.logger.log('obeservable: ', data);
                return data
            })
        );
    }

    /**
     * The one and only method to persist an option.
     * Updates also the optionList with resetLastSaveForOneOption
     * @param optionClass
     */
    public persistOption(optionClass: OptionClass) {
        const option = optionClass.optionData
        this.logger.log('persistOption', option);
        if (!option) {
            throw this.errorService.newError('persistOption: option undefined');
        }
        const index = option.index;
        this.logger.log('persist: ', option, index);
        if (!option.createdAt) {
            option.createdAt = firebase.database.ServerValue.TIMESTAMP;
        }
        option.updatedAt = firebase.database.ServerValue.TIMESTAMP;;
        // we don't want to persist the "Checked" attribute so we copy the option and remove the checked attribute
        const optionCopy = { ...option };
        optionCopy.checked = null;
        let path: string;
        if (this._optionList.isLegacyData) {
            path = `${this.pathService.getOptionsPath()}/${index}`
        } else {
            path = `${this.pathService.getOptionsArrayPath()}/${index}`
        }
        const ref: AngularFireObject<OptionData> = this.afd.object(path);
        ref.update(optionCopy);
        this.optionList.resetLastSaveForOneOption(optionClass)
    }

    /**
     * The one and only place to perist the sortMap of the optionList
     * Only for non legacy data
     * Updates also the sortList with resetSortMapLastSave
     *
     */
    public persistSortMap(force: boolean = false) {
        if (force || this.optionList.sortMapHasChangedSinceLastSave) {
            if (!this._optionList.isLegacyData) {
                this.logger.log('Persist sortmap')
                const ref: AngularFireObject<number[]> = this.afd.object(`${this.pathService.getOptionsSortMapPath()}`);
                ref.set(this._optionList.sortMap);
                this.optionList.resetSortMapLastSave()
            } else {
                this.logger.log('Dont persist sortmap its legacy data')
            }
        }
    }

    /**
     * Persists  options in optionList as well as the sortmap
     * If force is not set persists only changed options and sortmap
     * Updates also the optionList with resetLastSave()
     */
    public persist(force: boolean = false) {
        this.logger.log(' persist')
        this._optionList.forEachIncludingDeleted(optionClass => {
            if (force || this._optionList.optionHasChangedSinceLastSave(optionClass.index)) {
                this.persistOption(optionClass);
            }
        })
        this.persistSortMap(force)
        this._optionList.resetLastSave()
    }

    /**
   * save all options that are created by a the current particpant (owner options are not saved)
   * @param name
   */
    public saveOptionsCreatedByParticpant(name: string) {
        this._optionList.forEach(option => {
            if (option.isCreatedByParticipant && option.createdByUid === this.authService.uid) {
                option.createdByName = name;
                this.persistOption(option);
            }
        });
    }

    public markAsCreatedByParticpant(option: OptionClass) {
        option.setMarkAsCreatedBy(this.authService.displayName, this.authService.uid)
    }

    public get dateOptions(): OptionClass[] {
        return this._optionList.dateOptions
    }

    public copyAndPersistAllOptions(ownerId: string, pollId: string, newOwnerId: string, newSurveyId: string): Promise<boolean> {
        this.logger.log('copyAndPersistAllOptions? start; ownerId: ' + ownerId + ' surveId: ' + pollId + ' newSurveyId: ' + newSurveyId);
        const promise = new Promise<boolean>(async (resolve, reject) => {
            try {
                const ref = this.afd.database.ref(this.pathService.getOptionsPath(ownerId, pollId));
                ref.once('value', snapshot => {
                    if (snapshot.exists()) {
                        this.logger.log('copyAndPersistAllOptions Survey exists: ', snapshot.val());
                        this.copyAndPersistOptionData(snapshot.val(), newOwnerId, newSurveyId);
                        resolve(true);
                    } else {
                        this.logger.log('No option for this survey');
                        resolve(true);
                    }
                });
            } catch (error) {
                const errorMessage = 'Could not reach server. ' + error;
                this.logger.error(errorMessage);
                reject(error);
            }
        });
        return promise;
    }

    private copyAndPersistOptionData(options: OptionData[], newOwnerId: string, newSurveyId: string): void {
        this.logger.log('copyAFDOptionsObject: ', options);
        options.forEach((option, index) => {
            const path = `${this.pathService.getOptionsPath(newOwnerId, newSurveyId)}/${index}`;
            const ref: AngularFireObject<OptionData> = this.afd.object(path);
            ref.set(option);
        });
        this.logger.log('copyAndPersistOptionData: new option object with ID: ' + newSurveyId + ' has been created.');
    }

    public deleteOptions(ownerId: string, pollId: string) {

        const ref: AngularFireObject<any> = this.afd.object(this.pathService.getOptionsPath(ownerId, pollId));
        ref.remove();

        const sortMapRef: AngularFireObject<number[]> = this.afd.object(`${this.pathService.getOptionsSortMapPath(ownerId, pollId)}`);
        sortMapRef.remove();

    }

}
